import React, { Component } from "react";

import { OTPublisher } from "opentok-react";
import RadioButtons from "./RadioButtons";
import CheckBox from "./CheckBox";
const MicrophoneImage = require("../../assets/images/microphone.png");
const MicrophoneImageClose = require("../../assets/images/microphone_close.png");
const CameraSwapImage = require("../../assets/images/camera_swap.png");
export default class Publisher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      audio: true,
      video: true,
      videoSource: "camera",
      width: 0,
      height: 0,
      facingMode: 'user'
    };
    this.publisher = React.createRef();
  }

  setAudio = () => {
    this.setState({ audio: !this.state.audio });
  };

  setVideo = (video) => {
    this.setState({ video });
  };

  setVideoSource = (videoSource) => {
    this.setState({ videoSource });
  };

  onError = (err) => {
    this.setState({ error: `Failed to publish: ${err.message}` });
  };

  componentDidMount() { }

  switchCamera = async () => {
    if (this.publisher.current.getPublisher()) {
      const publisher = this.publisher.current.getPublisher();
      await publisher.cycleVideo();
      // if (this.state.facingMode == "user") {
      //   this.setState({ facingMode: 'environment' });
      // } else {
      //   this.setState({ facingMode: 'user' });
      // }

    }
  };

  turnVideo = (constraints) => {
    let video;
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        video = document.createElement("video")
        video.srcObject = stream
        video.play()
        video.onloadeddata = () => {
          // ctx.height = video.videoHeight
        }
      })

  }

  handleVideo = (cameraFacing) => {
    const constraints = {
      video: {
        facingMode: {
          exact: "user"
        }
      }
    }
    return constraints
  };

  render() {
    return (
      <>
        <div style={{ display: this.props.hide ? "none" : "block" }}>
          {this.state.error ? <div>{this.state.error}</div> : null}
          <OTPublisher
            ref={this.publisher}
            session={this.props.session}
            properties={{
              publishAudio: Boolean(this.state.audio),
              publishVideo: this.state.video,
              videoSource:
                this.state.videoSource === "screen" ? "screen" : undefined,
              resolution: "1280x720",
              height: window.innerHeight,
              width: "100%",
              // facingMode: this.state.facingMode,
              mirror: false,
              showControls: false,
            }}
            onError={this.onError}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              paddingTop: 10,
              paddingBottom: 10,
              bottom: 0,
            }}
          >
            {this.state.audio ? (
              <img
                onClick={this.setAudio}
                src={MicrophoneImage}
                alt="microphone"
              />
            ) : (
              <img
                onClick={this.setAudio}
                src={MicrophoneImageClose}
                alt="microphone"
              />
            )}
            <div style={{ width: 20 }} />
            <img
              onClick={this.switchCamera}
              src={CameraSwapImage}
              alt="camera"
            />
          </div>
        </div>
      </>
    );
  }
}
