import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from "axios";
import { Layout, Menu } from "antd";
import Home from "./../home";
import Camera from "../../components/cameraClient";
import HomeClient from '../../components/homeClient';
import './index.scss';
import { SUB_PATH } from "../../env";

const HomeRoute = (props) => {
    const { Header, Content, Footer, Sider } = Layout;
    useEffect(() => {
        const { pathname } = props.location;
    }, [])
    return (
        <React.Fragment>
            {props.location.pathname.includes("/client") ?
                <Layout className="layout" style={{ height: "100vh" }}>
                    <Content>
                        <Route
                            path={SUB_PATH + "/client"}
                            render={({ match: { url, params } }) => (
                                <>
                                    <Route path={`${url}`} params={params} component={HomeClient} exact />
                                    <Route path={`${url}/room`} params={params} component={Camera} />
                                </>
                            )}
                        />
                    </Content>
                </Layout> : null
            }
        </React.Fragment>
    )
}

export default HomeRoute;