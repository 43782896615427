import React, { useEffect, useState } from 'react';
import { Button, Modal, Checkbox, Layout } from 'antd';
import './index.scss';
import { PhoneOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import CM from '../../utils/CM'
import { SUB_PATH } from '../../env';
const ms = require('pretty-ms')

const ThankyouPage = (props) => {
    const { t, i18n } = useTranslation();
    const [params, setParams] = useState({});
    const { Header, Content, Footer, Sider } = Layout;
    const [room, setRoom] = useState('')

    useEffect(() => {
        getLanguage();
        const { search } = props.location;
        let params = queryString.parse(search);
        getRoom(params.room_id)
        return () => {

        }
    }, [])

    const getLanguage = async () => {
        const language = await CM.getStorage('language');
        if (language === null || language === "") {
        } else {
            i18n.changeLanguage(language);
        }
    }


    const getRoom = async (room_id) => {
        try {
            const result = await axios({
                url: "opentok/room_disconnect/client/" + room_id,
                method: "get",
            });
            const { data } = result.data;
            setRoom(data);
        } catch (error) {
            const { data, status } = error.response;
            const { message } = data;
            window.location.href = SUB_PATH + '/client';
            // window.location.href = '/404';
        }
    }


    return (
        <React.Fragment>
            <Layout className="layout" style={{ height: "100vh" }}>
                <Content>
                    <div className="thankyou-page-container" style={{ height: window.innerHeight }}>
                        {/* <div className="welcome-header" /> */}
                        <div className="thankyou-text-container">
                            <div className="text-center">
                                <label className="thankyou-text">{t('thankyou_title')}</label>
                            </div>
                            <div className="text-center">
                                <label className="thankyou-text">{t('thankyou_title2')}</label>
                            </div>
                        </div>
                        <div className="thankyou-text-container-callend">
                            <div className="text-center">
                                <div className="text-center">
                                    <Button
                                        type="default"
                                        style={{ backgroundColor: 'transparent', borderColor: '#FF9861' }}
                                        shape="circle"
                                        icon={<PhoneOutlined style={{ color: '#FF9861' }} />}
                                        size={"large"} />
                                </div>
                                <div className="text-center">
                                    <label className="time-text">{room.vdocalluse && ms(parseInt(room.vdocalluse * 1000))}</label>
                                </div>
                                <div className="text-center">
                                    <label className="call-end-text">{t('call_end')}</label>
                                </div>
                                <div>
                                    <label className="text-white">{t('customer_name')} : {room.custname ?? "-"}</label>
                                </div>
                                <div>
                                    <label className="text-white">
                                        {t("crm_ref")} : {room.crm_ref ?? "-"}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        </React.Fragment>
    )
};

export default ThankyouPage;