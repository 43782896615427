import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Form,
  Input,
  Button,
  Checkbox,
  Layout,
  Space,
  Col,
  Modal,
  Card,
} from "antd";
import axios from "axios";
import "./index.scss";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import thaireeLogo from "../../assets/images/Easy_LOGO.png";
import { SUB_PATH } from "../../env";
// import axios from 'axios';
const Login = () => {
  useEffect(() => {
    if (isMobile) {
      history.push(SUB_PATH + '/client')
    }
    document.title = "EASY Insure Broker";
    return () => { };
  }, []);

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const onFinish = async (values) => {
    setloading(true);
    try {
      const result = await axios({
        url: "login",
        method: "post",
        data: values,
      });
      if (result.status === 200) {
        setloading(false);
        const { data } = result;
        const { token, user } = data;
        await localStorage.setItem("token", token);
        await localStorage.setItem("role", user.userrole);
        await localStorage.setItem("user", JSON.stringify(user));
        history.push(SUB_PATH + '/adminvideocall')
      }
    } catch (error) {
      console.log(error)
      // const { data, status } = error.response;
      // const { message } = data;
      // var mess = t("incorrect_username_or_password");
      // setloading(false);
      // Modal.error({
      //   okText: "ปิด",
      //   content: mess,
      //   onOk: () => { },
      // });
    }
  };

  const func_changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const { Header, Footer } = Layout;
  return (
    <React.Fragment>
      <Header>
        <div className="flex-container" style={{justifyContent: "space-between"}}>
          <div>
            <img src={thaireeLogo} className="logo" />
          </div>
          <div>
            <div style={{height: 45, textAlign: "right"}}>
              <label className="login-info">บริการตลอด 24 ชม.</label>
            </div>
            <div style={{marginTop: -25}}>
              <label className="login-info" style={{fontWeight:"bold", fontSize: 25}}>02-801-9000</label>
            </div>
          </div>
        </div>
      </Header>
      <div className="antd-login-user-layout-container">
        <div className="antd-pro-layouts-user-layout-content">
          <div className="antd-pro-pages-user-login-style-main">
            <Form
              name="register"
              className="login-form"
              onFinish={onFinish}
              initialValues={{
                residence: ["zhejiang", "hangzhou", "xihu"],
                prefix: "86",
              }}
              layout="vertical"
            // scrollToFirstError
            >
              <Card title="Sign IN">
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                  size="middle"
                >
                  <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ marginLeft: "auto" }}>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ marginLeft: "5px", fontWeight: i18n.language == "th" ? "bold" : "normal" }}
                          onClick={() => {
                            func_changeLanguage("th");
                          }}
                        >
                          th
                        </div>
                        <div style={{ marginLeft: "5px" }}>/</div>
                        <div
                          style={{ marginLeft: "5px", fontWeight: i18n.language == "en" ? "bold" : "normal" }}
                          onClick={() => {
                            func_changeLanguage("en");
                          }}
                        >
                          en
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form.Item
                    name="username"
                    label={t("username")}
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_username"),
                      },
                    ]}
                  >
                    <Input autoComplete={false} placeholder={t("username")} />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_password"),
                      },
                    ]}
                    label={t("password")}
                  >
                    <Input
                      autoComplete={false}
                      type="password"
                      placeholder={t("password")}
                    />
                  </Form.Item>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <Button
                      // style={{ marginLeft: "auto" }}
                      htmlType="submit"
                      type="custom"
                      // className="btn-primary"
                      block
                    //className="ant-button"
                    >
                      Log in
                    </Button>
                  </div>
                </Space>
              </Card>
            </Form>
          </div>
        </div>
        <div className="text-center" style={{ marginBottom: 10 }}>
          <label className="text-white text-center">
            © 2022 Powered by BlueVenture Tech Co., Ltd.
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Login;
