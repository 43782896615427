import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  Layout,
  Breadcrumb,
  Table,
  Space,
  message,
  Select,
  Modal,
  Form,
  DatePicker,
  Upload,
} from "antd";
import "./index.scss";
import queryString from "query-string";
import CM from "../../utils/CM";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  isMobile,
  isAndroid,
  isIOS,
  isSafari,
  isChrome,
} from "react-device-detect";
import { WEB_FILE } from "../../env";
import Moment from "react-moment";

const { Meta } = Card;
var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();

const ViewGallery = (props) => {
  useEffect(() => {
    var q = new URLSearchParams(props.location.search).get("q");
    console.log(q);
    func_viewImageAndVideo(q);
    // console.log("userState::", userState);
  }, []);

  const [dataImage, setDataImage] = useState({ insurance: [] });
  const [Vdorecord, setVdorecord] = useState([]);

  const func_viewImageAndVideo = async (q) => {
    var room_id = q != null && q != undefined ? atob(q) : "";
    await CM.setHeaderAuth();
    const result = await axios({
      url: `/backoffice/tran_vdocall_link_image?room_id=${room_id}`,
      method: "get",
    });
    if (result.status === 200) {
      const { data } = result;
      for (let i = 0; i < data.length; i++) {
        data[i].checked = false;
      }

      setDataImage(data);
      console.log(data);
    }

    await CM.setHeaderAuth();
    const result2 = await axios({
      url: `/backoffice/tran_vdocall_link_record?room_id=${room_id}`,
      method: "get",
    });
    if (result2.status === 200) {
      const { data } = result2;
      for (let i = 0; i < data.length; i++) {
        data[i].checked = false;
      }
      setVdorecord(data);
    }
  };

  const columnsVdo = [
    {
      title: "ระยะเวลา (นาที)",
      dataIndex: "duration",
      render: (text, record) => {
        return fancyTimeFormat(text) ? fancyTimeFormat(text) : 1;
      },
    },
    {
      title: "ขนาดไฟล์",
      dataIndex: "size",
      render: (text) => `${bytesToSize(text)}`,
    },
    {
      title: "VDO",
      dataIndex: "video_id",
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() => {
            window.open(
              record.url,
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        >
          เปิด VDO
        </Button>
      ),
    },
  ];

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  function fancyTimeFormat(duration) {
    // minutes and seconds
    var mins = ~~(duration / 60);
    return mins;
  }

  return (
    <div style={{ height: "100%" }}>
      <Row>
        <Col span={24}>
          <Space direction="vertical">
            <Row>
              <Col>
                <Space direction="vertical">
                  <Table
                    tableLayout={"fixed"}
                    className="virtual-table"
                    columns={columnsVdo}
                    rowKey="id"
                    scroll={true}
                    dataSource={Vdorecord}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {}, // click row
                      };
                    }}
                  />
                </Space>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col style={{ marginTop: "10px" }} span={24}>
                <h2>ด้านหน้ารถยนต์</h2>
              </Col>
              {dataImage.insurance &&
                dataImage.insurance.map((value, index) => (
                  <Col span={isMobile ? 24 : 4}>
                    <a
                      href={WEB_FILE + value.imagepath.replace("./public", "")}
                      target="_blank"
                    >
                      <Card
                        style={{ marginTop: "10px" }}
                        hoverable
                        cover={
                          <img
                            alt="falcon"
                            src={
                              WEB_FILE + value.imagepath.replace("./public", "")
                            }
                          />
                        }
                      >
                        <Meta
                          style={{ fontSize: 12 }}
                          title="ด้านหน้ารถยนต์"
                          description={
                            value.create_date ? (
                              <Moment format="DD-MM-YYYY HH:mm:ss">
                                {value.create_date}
                              </Moment>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Card>
                    </a>
                  </Col>
                ))}
              <Col style={{ marginTop: "10px" }} span={24}>
                <h2>ด้านหลังรถยนต์</h2>
              </Col>
              {dataImage.parties &&
                dataImage.parties.map((value, index) => (
                  <Col span={isMobile ? 24 : 4}>
                    <a
                      href={WEB_FILE + value.imagepath.replace("./public", "")}
                      target="_blank"
                    >
                      <Card
                        style={{ marginTop: "10px" }}
                        hoverable
                        cover={
                          <img
                            alt="falcon"
                            src={
                              WEB_FILE + value.imagepath.replace("./public", "")
                            }
                          />
                        }
                      >
                        <Meta
                          style={{ fontSize: 12 }}
                          title="ด้านหลังรถยนต์"
                          description={
                            value.create_date ? (
                              <Moment format="DD-MM-YYYY HH:mm:ss">
                                {value.create_date}
                              </Moment>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Card>
                    </a>
                  </Col>
                ))}
              <Col style={{ marginTop: "10px" }} span={24}>
                <h2>ด้านข้างรถยนต์</h2>
              </Col>
              {dataImage.asset &&
                dataImage.asset.map((value, index) => (
                  <Col span={isMobile ? 24 : 4}>
                    <a
                      href={WEB_FILE + value.imagepath.replace("./public", "")}
                      target="_blank"
                    >
                      <Card
                        style={{ marginTop: "10px" }}
                        hoverable
                        cover={
                          <img
                            alt="falcon"
                            src={
                              WEB_FILE + value.imagepath.replace("./public", "")
                            }
                          />
                        }
                      >
                        <Meta
                          style={{ fontSize: 12 }}
                          title="ด้านข้างรถยนต์"
                          description={
                            value.create_date ? (
                              <Moment format="DD-MM-YYYY HH:mm:ss">
                                {value.create_date}
                              </Moment>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Card>
                    </a>
                  </Col>
                ))}
              <Col style={{ marginTop: "10px" }} span={24}>
                <h2>เลขตัวถัง</h2>
              </Col>

              {dataImage.numberasset &&
                dataImage.numberasset.map((value, index) => (
                  <Col span={isMobile ? 24 : 4}>
                    <a
                      href={WEB_FILE + value.imagepath.replace("./public", "")}
                      target="_blank"
                    >
                      <Card
                        style={{ marginTop: "10px" }}
                        hoverable
                        cover={
                          <img
                            alt="falcon"
                            src={
                              WEB_FILE + value.imagepath.replace("./public", "")
                            }
                          />
                        }
                      >
                        <Meta
                          style={{ fontSize: 12 }}
                          title="เลขตัวถัง"
                          description={
                            value.create_date ? (
                              <Moment format="DD-MM-YYYY HH:mm:ss">
                                {value.create_date}
                              </Moment>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Card>
                    </a>
                  </Col>
                ))}

              <Col style={{ marginTop: "10px" }} span={24}>
                <h2>อื่นๆ</h2>
              </Col>
              {dataImage.asset2 &&
                dataImage.asset2.map((value, index) => (
                  <Col span={isMobile ? 24 : 4}>
                    <a
                      href={WEB_FILE + value.imagepath.replace("./public", "")}
                      target="_blank"
                    >
                      <Card
                        style={{ marginTop: "10px" }}
                        hoverable
                        cover={
                          <img
                            alt="falcon"
                            src={
                              WEB_FILE + value.imagepath.replace("./public", "")
                            }
                          />
                        }
                      >
                        <Meta
                          style={{ fontSize: 12 }}
                          title="อื่นๆ"
                          description={
                            value.create_date ? (
                              <Moment format="DD-MM-YYYY HH:mm:ss">
                                {value.create_date}
                              </Moment>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Card>
                    </a>
                  </Col>
                ))}
            </Row>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default ViewGallery;
