import React, { useEffect, useState } from "react";
import Login from "./components/login";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  Switch,
} from "react-router-dom";
import axios from "axios";
import { Layout, Menu } from "antd";
import Home from "./components/home";
import Camera from "./components/camera";
import MenuLayout from "./components/menu";
import HomeRoute from "./components/homeRoute";
import ClientRoute from "./components/clientRoute";
import ViewGallery from "./components/viewGallery";
import Page404 from "./components/error/404";
import Page404Client from "./components/error/404client";
import ThankyouPage from "./components/thankyou";
import ThankyouPageAdmin from "./components/thankyouAdmin";
import AdminVideoCall from "./components/adminVideoCall";
import "./configs/axios";
import { isMobile } from "react-device-detect";
import { SUB_PATH } from "./env";


const HomeRouteComponent = withRouter((props) => <HomeRoute {...props} />);
const ClientRouteComponent = withRouter((props) => <ClientRoute {...props} />);

function App(props) {
  const { Header, Content, Footer, Sider } = Layout;

  useEffect(() => {
    if (!isMobile) {
      getToken();
    }
    return () => { };
  }, []);

  const getToken = async () => {
    const user = await localStorage.getItem("token");
    const { pathname } = window.location;
    if (
      pathname != "" &&
      pathname != "/" &&
      pathname != "/404" &&
      !pathname.includes("client") &&
      !pathname.includes("viewGallery")
    ) {
      if (user == null) {
        // window.location.href = "/";
      }
    }
  };

  return (
    <Router>
      <Switch>
        <Route exact path={`${SUB_PATH}/`} component={Login} />
        <Route path={`${SUB_PATH}/home`} component={HomeRouteComponent} />
        <Route path={`${SUB_PATH}/client`} component={ClientRouteComponent} />
        <Route path={`${SUB_PATH}/thankyou`} component={ThankyouPage} />
        <Route path={`${SUB_PATH}/thankyouAdmin`} component={ThankyouPageAdmin} />
        <Route path={`${SUB_PATH}/adminvideocall`} component={AdminVideoCall} />
        <Route path={`${SUB_PATH}/viewGallery`} component={ViewGallery} />

        <Route component={Page404} />
      </Switch>
      {/* <Route path='*' exact={true} component={Page404} /> */}
    </Router>
  );
}

export default App;
