import React, { Component } from 'react';

import { OTSubscriber } from 'opentok-react'
import CheckBox from './CheckBox';

export default class Subscriber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audio: true,
      video: false
    };

    this.subscriberEventHandlers = {
      error: (error) => {
        console.log(`There was an error with the subscriber: ${error}`);
      },
      connected: () => {
        console.log('subscriberEventHandlers Connected');
        this.props.subscriberConnected();
      },
      disconnected: () => {
        console.log('subscriberEventHandlers DisConnected');
        this.props.subscriberDisConnected();
      }
    };
  }

  componentDidMount() {

  }

  setAudio = (audio) => {
    this.setState({ audio });
  }

  setVideo = (video) => {
    this.setState({ video });
  }

  onError = (err) => {
    alert(err.message);
    this.setState({ error: `Failed to subscribe: ${err.message}` });
  }





  render() {
    return (
      <div className="subscriber_client">
        {this.state.error ? <div>{this.state.error}</div> : null}
        <OTSubscriber

          properties={{
            subscribeToAudio: this.state.audio,
            subscribeToVideo: this.state.video,
            showControls: false,
            // style: {
            //   backgroundImageURI: 'https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej.png',
              
            // }
          }}
          eventHandlers={this.subscriberEventHandlers}
          onError={this.onError}
          retry={true}
          maxRetryAttempts={1}
          retryAttemptTimeout={2000}
        />
        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: 10, top: 0 }}>
          <label style={{ color: '#fff' }}>Admin.</label>
          <label style={{ color: '#fff' }}>Ken L.</label>
        </div> */}

      </div>
    );
  }
}
