import React, { Component } from "react";

import { OTPublisher } from "opentok-react";
import RadioButtons from "./RadioButtons";
import CheckBox from "./CheckBox";
import { WEB_FILE } from "../../env";

export default class Publisher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      audio: true,
      video: false,
      videoSource: "camera",
    };
  }

  setAudio = (audio) => {
    this.setState({ audio });
  };

  setVideo = (video) => {
    this.setState({ video });
  };

  setVideoSource = (videoSource) => {
    this.setState({ videoSource });
  };

  onError = (err) => {
    this.setState({ error: `Failed to publish: ${err.message}` });
  };

  componentDidMount() {
    console.log('props::', this.props);
  }

  render() {
    return (
      <>
        <div className="publisher_host">
          {this.state.error ? <div>{this.state.error}</div> : null}

          <OTPublisher
            session={this.props.session}
            properties={{
              publishAudio: true,
              publishVideo: this.state.video,
              style: {
                backgroundImageURI: this.props.userState.profile_image ? WEB_FILE + this.props.userState.profile_image.replace("./public", "") : "",
              }
              // videoSource: this.state.videoSource === 'screen' ? 'screen' : undefined,
            }}
            onError={this.onError}
          ></OTPublisher>
        </div>
      </>
    );
  }
}
