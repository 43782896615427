import React from 'react';
import { Menu, Row, Col, Button, Typography } from "antd";
import { Link } from "react-router-dom";
import './index.scss';
import { AudioOutlined, LogoutOutlined } from '@ant-design/icons';
import { SUB_PATH } from '../../env';
const { Text } = Typography;

const { SubMenu } = Menu;
const MenuLayout = props => {
    const logout = async () => {
        await localStorage.clear();
        window.location.href = SUB_PATH + "/";
    }
    return (
        <>
            <Menu
                style={{ width: 256, padding: 10 }}
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['menu']}
                mode="inline"
                theme="dark"
            >
                <Row gutter={16} style={{ color: "white" }}>
                    {/* <Col span={16} style={{ fontSize: "24px", marginTop: "20px", marginLeft: "15px" }}>
                        <p>Jane L.</p>
                    </Col> */}
                    {/* <Col span={24} style={{ fontSize: "8px", marginLeft: "15px" }}>
                        <p>Ref Code: 110-43321</p>
                    </Col> */}
                </Row>
                {/* <SubMenu
                    key="menu"
                    title={
                        <span>
                            <span>Menu</span>
                        </span>
                    }
                >
                    <Menu.Item key="home">Home
                        <Link to="/home" />
                    </Menu.Item>
                    <Menu.Item key="2">Claim History</Menu.Item>
                    <Menu.Item key="3">Setting</Menu.Item>
                </SubMenu> */}
                <Menu.Item onClick={() => logout()} key={"4"} icon={<LogoutOutlined style={{ color: '#ff4d4f' }} />}>
                    <Text type="danger">Log out</Text>
                </Menu.Item>
            </Menu>
        </>
    );
};

export default MenuLayout;