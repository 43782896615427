import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Checkbox,
  Layout,
  Form,
  Input,
  Col,
  Row,
  Space,
  Upload,
  Card,
} from "antd";
import "./index.scss";
import { PhoneOutlined } from "@ant-design/icons";
import queryString from "query-string";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CM from "../../utils/CM";
import {
  UploadOutlined,
  PlusOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { SUB_PATH } from "../../env";
const ms = require("pretty-ms");

const ThankyouPage = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [params, setParams] = useState({});
  const { Header, Content, Footer, Sider } = Layout;
  const [room, setRoom] = useState("");
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setloading] = useState(false);
  const [isModalFile, setisModalFile] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileListPentie, setFileListPentie] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [partieLoading, setPartieLoading] = useState(false);
  const [fileCustomerSuccess, setFileCustomerSuccess] = useState(false);
  const [filePentieSuccess, setFilePentieSuccess] = useState(false);

  const [modalCustomerPhone, setModalCustomerPhone] = useState("");
  const [modalPentiePhone, setModalPentiePhone] = useState("");

  const [fileCustomerLink, setCustomerLink] = useState("");
  const [filePentieLink, setPentieLink] = useState("");

  const [submitCustomerSuccess, setSubmitCustomerSuccess] = useState(false);
  const [submitPentieSuccess, setSubmitPentieSuccess] = useState(false);

  const [formCustomer] = Form.useForm();
  const [formPentie] = Form.useForm();

  useEffect(() => {
    const { search } = props.location;
    let params = queryString.parse(search);

    getRoom(params.room_id);
    return () => { };
  }, []);

  const getRoom = async (room_id) => {
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/room_disconnect/admin/" + room_id,
        method: "get",
      });
      const { data } = result.data;
      console.log("room::", data)
      setRoom(data);
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      window.location.href = SUB_PATH + "/404";
    }
  };

  const onHome = async () => {
    setloading(true);
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/update",
        method: "post",
        data: { sysref: room.sysref, note: note, tel_partie: phone },
      });
      if (result.status === 200) {
        const { data } = result.data;
        history.push(SUB_PATH + '/adminvideocall')
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;

      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
    setloading(false);
  };

  const SendCliam = async () => {
    setloading(true);
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/update",
        method: "post",
        data: { sysref: room.sysref, note: note, tel_partie: phone },
      });
      if (result.status === 200) {
        const { data } = result.data;
        formCustomer.setFieldsValue({
          customer_phone_modal: room.tel,
        });
        formPentie.setFieldsValue({
          pentie_phone_modal: phone,
        });
        setModalPentiePhone(phone);
        setModalCustomerPhone(room.tel);
        setisModalFile(true);
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;

      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
    setloading(false);
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: async (file) => {
      setCustomerLoading(true);
      setFileCustomerSuccess(false);
      const formData = new FormData();
      if (file) {
        formData.append("image", file);
      }
      try {
        const result = await axios({
          url: `opentok/uploadfilecliam?room_id=${room.sysref}&case_type=customer`,
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const { data } = result;
        setCustomerLink(data.data.imagepath);
        setFileCustomerSuccess(true);
        setFileList([file]);
        setCustomerLoading(false);
      } catch (error) {
        Modal.error({
          okText: "ปิด",
          content: "ส่งข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
          onOk: () => {
            // onReset();
          },
        });
      }
      return false;
    },
    fileList,
  };

  const uploadPropsPentie = {
    name: "file",
    multiple: false,
    showUploadList: false,
    onRemove: (file) => {
      setFileListPentie([]);
    },
    beforeUpload: async (file) => {
      setPartieLoading(true);
      setFilePentieSuccess(false);
      const formData = new FormData();
      if (file) {
        formData.append("image", file);
      }
      try {
        const result = await axios({
          url: `opentok/uploadfilecliam?room_id=${room.sysref}&case_type=partie`,
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const { data } = result;
        setPentieLink(data.data.imagepath);
        setFilePentieSuccess(true);
        setFileListPentie([file]);
        setPartieLoading(false);
      } catch (error) {
        setPartieLoading(false);
        Modal.error({
          okText: "ปิด",
          content: "ส่งข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
          onOk: () => {
            // onReset();
          },
        });
      }
      return false;
    },
    fileList,
  };

  const openModal = () => { };

  const closeModal = () => { };

  const onFinishCustomer = async (values) => {
    if (!(fileList.length > 0)) {
      Modal.error({
        okText: "ปิด",
        content: "กรุณาอัพโหลดไฟล์ก่อน",
        onOk: async () => { },
      });
      return false;
    }
    setSubmitCustomerSuccess(false);
    setloading(true);
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/send_doc",
        method: "post",
        data: {
          phone: modalCustomerPhone,
          link: fileCustomerLink,
          type: "customer",
          role: room.user_role,
          ref: room.crm_ref,
        },
      });
      if (result.status === 200) {
        const { data, message } = result.data;
        setloading(false);
        setSubmitCustomerSuccess(true);
      }
    } catch (error) {
      setSubmitCustomerSuccess(false);
      const { data, status } = error.response;
      const { message } = data;
      setloading(false);
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
  };

  const onFinishPentie = async (values) => {
    if (!(fileListPentie.length > 0)) {
      Modal.error({
        okText: "ปิด",
        content: "กรุณาอัพโหลดไฟล์ก่อน",
        onOk: async () => { },
      });
      return false;
    }
    setSubmitPentieSuccess(false);
    setloading(true);
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/send_doc",
        method: "post",
        data: {
          phone: modalPentiePhone,
          link: filePentieLink,
          type: "pentie",
          role: room.user_role,
          ref: room.crm_ref,
        },
      });
      if (result.status === 200) {
        const { data, message } = result.data;
        setloading(false);
        setSubmitPentieSuccess(true);
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      setloading(false);
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const backtoHome = () => {
    window.location.href = SUB_PATH + "/adminvideocall";
  };

  return (
    <React.Fragment>
      <Layout className="layout" style={{ height: "100vh" }}>
        <Content>
          <Form onFinish={SendCliam}>
            <div
              className="thankyou-page-container"
              style={{ height: window.innerHeight }}
            >
              {/* <div className="welcome-header" /> */}
              <div className="thankyou-text-container">
                {/* <div className="text-center">
                                <label className="thankyou-text">{t('thankyou_title')}</label>
                            </div>
                            <div className="text-center">
                                <label className="thankyou-text">{t('more_information')}</label>
                            </div>
                            <div className="text-center">
                                <label className="thankyou-text">{t('contact_us')}</label>
                            </div> */}
              </div>
              <div className="thankyou-text-container-callend">
                <div className="text-center">
                  <div className="text-center">
                    <Button
                      type="default"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "#FF9861",
                      }}
                      shape="circle"
                      icon={<PhoneOutlined style={{ color: "#FF9861" }} />}
                      size={"large"}
                    />
                  </div>
                  <div className="text-center">
                    <label className="time-text">
                      {room.vdocalluse && ms(parseInt(room.vdocalluse * 1000))}
                    </label>
                  </div>
                  <div className="text-center">
                    <label className="call-end-text">{t("call_end")}</label>
                  </div>
                  <div>
                    <label className="text-white">
                      {t("customer_name")} : {room.custname ?? "-"}
                    </label>
                  </div>
                  <div>
                    <label className="text-white">
                      {t("crm_ref")} : {room.crm_ref ?? "-"}
                    </label>
                  </div>
                  <div>
                    <label className="text-white">
                      {t("photo")} : {room.image_count ?? "-"}
                    </label>
                  </div>
                  <div>
                    <label className="text-white">
                      {t("videos")} : {room.record_count ?? "-"}
                    </label>
                  </div>
                  <div style={{ height: 10 }} />
                  {/* <div>
                    <Form.Item
                      placeholder={t("phone_number_of_the_customer")}
                      name="customer_phone_modal"
                      label=""
                      rules={[
                        {
                          pattern: "^[0-9]{10}$",
                          message: t("Invalid_phone_number_format"),
                        },
                      ]}
                    >
                      <Input
                        style={{ maxWidth: 300 }}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder={""}
                        name="phone"
                      />
                    </Form.Item>
                  </div>
                  <div style={{ height: 10 }} /> */}
                  <div>
                    <textarea
                      style={{ minWidth: 300, padding: 10 }}
                      onChange={(e) => setNote(e.target.value)}
                      placeholder={t("additional_note")}
                      name="note"
                      rows={2}
                    />
                  </div>
                  <div style={{ height: 10 }} />
                  {/* <Button
                                    loading={loading}
                                    onClick={onHome}
                                    type="primary"
                                    size={"large"}>กลับหน้าแรก</Button> */}
                  <Button
                    htmlType={"submit"}
                    loading={loading}
                    // onClick={SendCliam}
                    type="primary"
                    size={"large"}
                  >
                    {t("save")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Content>
        <Modal
          style={{ height: "100%" }}
          title={""}
          visible={isModalFile}
          onOk={openModal}
          onCancel={closeModal}
          okText={t("vdo_call_btn_back")}
          cancelButtonProps={{
            hidden: true,
          }}
          footer={
            <Button onClick={() => backtoHome()} type="default">
              {t("vdo_call_btn_back")}
            </Button>
          }
          width={400}
          bodyStyle={{ paddingTop: 20 }}
        // onCancel={handleCancel}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form
                layout={"vertical"}
                form={formCustomer}
                initialValues={{
                  layout: "vertical",
                }}
                onFinish={onFinishCustomer}
              >
                <div style={{ marginBottom: 10 }}>{t("send_to_customer")}</div>

                <Form.Item
                  placeholder=""
                  name="customer_phone_modal"
                  label={t("phone_number_of_the_parties")}
                  rules={[
                    {
                      required: true,
                      message: t("vphone_number"),
                    },
                    {
                      pattern: "^[0-9]{10}$",
                      message: t("Invalid_phone_number_format"),
                    },
                  ]}
                >
                  <Input
                    value={modalCustomerPhone}
                    onChange={(e) => setModalCustomerPhone(e.target.value)}
                  />
                </Form.Item>
                <Row>
                  <Space style={{ marginTop: 20 }}>
                    <Upload multiple={false} {...uploadProps}>
                      <Button
                        loading={customerLoading}
                        icon={<UploadOutlined />}
                      >
                        {fileList && fileList[0] && fileList[0].name
                          ? fileList[0].name
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                    {fileCustomerSuccess && (
                      <span style={{ color: "#28a745" }}>
                        อัพโหลดไฟล์สำเร็จ
                      </span>
                    )}
                  </Space>
                </Row>
                <Row>
                  <Space>
                    <Button
                      name="submit_customer"
                      htmlType="submit"
                      loading={loading}
                      type="primary"
                    >
                      {t("btn_send")}
                    </Button>
                    {submitCustomerSuccess && (
                      <span style={{ color: "#28a745" }}>ส่ง SMS สำเร็จ</span>
                    )}
                  </Space>
                </Row>
              </Form>
            </Col>
            {/*<Col span={12}>
              <Form
                layout={"vertical"}
                form={formPentie}
                initialValues={{
                  layout: "vertical",
                }}
                onFinish={onFinishPentie}
              >
                <div style={{ marginBottom: 10 }}>{t("send_to")}</div>
                <Form.Item
                  placeholder=""
                  name="pentie_phone_modal"
                  label={t("phone_number_of_the_customer")}
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอกเบอร์โทรศัพท์",
                    },
                    {
                      pattern: "^[0-9]{10}$",
                      message: "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง",
                    },
                  ]}
                >
                  <Input
                    value={modalPentiePhone}
                    onChange={(e) => setModalPentiePhone(e.target.value)}
                  />
                </Form.Item>

                <Row>
                  <Space style={{ marginTop: 20 }}>
                    <Upload multiple={false} {...uploadPropsPentie}>
                      <Button
                        loading={customerLoading}
                        icon={<UploadOutlined />}
                      >
                        {fileListPentie &&
                        fileListPentie[0] &&
                        fileListPentie[0].name
                          ? fileListPentie[0].name
                          : "Click to Upload"}
                      </Button>
                    </Upload>
                    {filePentieSuccess && (
                      <span style={{ color: "#28a745" }}>
                        อัพโหลดไฟล์สำเร็จ
                      </span>
                    )}
                  </Space>
                </Row>
                <Row>
                  <Space>
                    <Button
                      name="submit_pentie"
                      htmlType="submit"
                      loading={loading}
                      type="primary"
                    >
                      {t("download")}
                    </Button>
                    {submitPentieSuccess && (
                      <span style={{ color: "#28a745" }}>ส่ง SMS สำเร็จ</span>
                    )}
                  </Space>
                </Row>
              </Form>
            </Col> */}
          </Row>
        </Modal>
      </Layout>
    </React.Fragment>
  );
};

export default ThankyouPage;
