import React, { useState } from "react";
import {
  Menu,
  Row,
  Col,
  Steps,
  Button,
  message,
  Card,
  Form,
  Space,
  Input,
  Descriptions,
  Modal,
} from "antd";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "./index.scss";
import axios from "axios";
import CM from "../../utils/CM";
import { useTranslation } from "react-i18next";
import { SUB_PATH } from "../../env";

const { Step } = Steps;

const steps = [
  {
    title: "Create Link",
    content: "First-content",
  },
  {
    title: "Send Link",
    content: "Last-content",
  },
];

const Home = (props) => {
  const [currentStep, setcurrentStep] = useState(0);
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [link, setlink] = useState("");
  const [room_id, setRoom] = useState("");
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [ref, setref] = useState("");
  const [userState, setuserState] = useState({});

  const next = () => {
    const current = currentStep + 1;
    setcurrentStep(current);
  };

  const { t, i18n } = useTranslation();

  const prev = async () => {
    const current = currentStep - 1;
    setloading2(true);
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/delete",
        method: "post",
        data: { room_id: room_id },
      });
      if (result.status === 200) {
        setcurrentStep(current);
      }
    } catch (error) {
      window.location.reload();
    }
    setloading2(false);
  };

  const sendLink = async () => {
    setloading2(true);
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/send_sms",
        method: "post",
        data: { phone: phone, link: link, ref: userState.crm_ref },
      });
      if (result.status === 200) {
        let claim = await localStorage.getItem("claim");
        claim = JSON.parse(claim);
        console.log('claim::', claim)
        const result2 = await axios({
          url: "/backoffice/trancall/updatestatus",
          method: "post",
          data: { id: claim.id, status: "O" },
        });
        const { data, message } = result.data;
        var mess = t("success_send");
        setloading2(false);
        Modal.success({
          okText: "done",
          content: mess,
          onOk: () => {
            window.location.href = SUB_PATH + "/home/camera?room_id=" + room_id;
          },
        });
      }
    } catch (error) {
      console.log(error)
      const { data, status } = error.response;
      const { message } = data;
      setloading2(false);
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
  };

  const func_changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const onFinish = async (values) => {
    setloading(true);
    values.reference = userState.ref_no;
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/connect",
        method: "post",
        data: values,
      });
      if (result.status === 200) {
        const { data } = result.data;
        const { link, room_id } = data;
        setloading(false);
        setlink(link);
        setRoom(room_id);
        next();
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      setloading(false);
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
  };

  const func_coppy = () => {
    navigator.clipboard.writeText(link);
  };

  const firstStep = (step) => {
    if (step === 0) {
      return (
        <div
          className="antd-pro-layouts-user-layout-content"
          style={{ paddingTop: 0 }}
        >
          <div className="">
            <Form
              name="register"
              className="customer-form"
              layout="vertical"
              onFinish={onFinish}
            >
              <div>
                <img
                  style={{ objectFit: "cover", width: "100%" }}
                  alt="bgCard"
                  src={require("../../assets/images/CardBG.png")}
                />
              </div>

              <div className="custom-header">{t("starting_vdo_call")}</div>
              <Card>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                  size="middle"
                >
                  {/* <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ marginLeft: "auto" }}>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ marginLeft: "5px", fontWeight: i18n.language == "th" ? "bold" : "normal" }}
                          onClick={() => {
                            func_changeLanguage("th");
                          }}
                        >
                          th
                        </div>
                        <div style={{ marginLeft: "5px" }}>/</div>
                        <div
                          style={{ marginLeft: "5px", fontWeight: i18n.language == "en" ? "bold" : "normal" }}
                          onClick={() => {
                            func_changeLanguage("en");
                          }}
                        >
                          en
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <Form.Item
                    initialValue={userState.tel}
                    placeholder={t("customer_phone_number")}
                    name="phone"

                    style={{ opacity: 0.8 }}
                    label={t("customer_phone_number")}
                    rules={[
                      {
                        required: true,
                        message: t("vphone_number"),
                      },
                      {
                        pattern: "^[0-9]{10}$",
                        message: t("Invalid_phone_number_format"),
                      },
                    ]}
                  >
                    <Input
                      readOnly={true}
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={userState.crm_ref}
                    name="reference"

                    style={{ opacity: 0.8 }}
                    rules={[
                      {
                        required: false,
                        message: t("vcrm_ref"),
                      },
                    ]}
                    label={t("crm_ref")}
                  >
                    <Input

                      readOnly={true}
                      value={ref}
                      onChange={(e) => setref(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    style={{ opacity: 0.8 }}
                    readOnly={true}
                    initialValue={
                      userState.fristname + " " + userState.lastname
                    }
                    rules={[
                      {
                        required: true,
                        message: t("vcustomer_name"),
                      },
                    ]}
                    label={t("customer_name")}
                  >
                    <Input
                      readOnly={true}
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </Form.Item>
                  <div className="steps-action">
                    {currentStep < steps.length - 1 && (
                      <div className="flex">
                        <Button
                          style={{ marginLeft: "auto" }}
                          type="primary"
                          loading={loading}
                          htmlType="submit"
                          className="btn-primary"
                        >
                          Create Link
                        </Button>
                      </div>
                    )}
                  </div>
                </Space>
              </Card>
            </Form>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="antd-pro-layouts-user-layout-content"
          style={{ paddingTop: 0 }}
        >
          <div className="">
            <Form
              name="register"
              className="customer-form"
              // onFinish={onFinish}
              initialValues={{
                residence: ["zhejiang", "hangzhou", "xihu"],
                prefix: "86",
              }}
              layout="vertical"
            >
              <div>
                <img
                  style={{ objectFit: "cover", width: "100%" }}
                  alt="bgCard"
                  src={require("../../assets/images/CardBG.png")}
                />
              </div>

              <div className="custom-header">{t("starting_vdo_call")}</div>
              <Card>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                  size="middle"
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Descriptions layout="vertical">
                        <Descriptions.Item label={t("customer_name") + ":"}>
                          {name}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={12}>
                      <Descriptions layout="vertical">
                        <Descriptions.Item label={t("customer_phone_number") + ":"}>
                          {phone}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                  <Form.Item
                    name="createLink"
                    initialValue={link}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                    label="Create Link"
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "80%" }}>
                        <Input readOnly value={link} />
                      </div>
                      <div style={{ width: "20%" }}>
                        <Button
                          onClick={() => {
                            func_coppy();
                          }}
                          size="middle"
                        >
                          Copy
                        </Button>
                      </div>
                    </div>
                  </Form.Item>
                  <div className="flex">
                    {currentStep > 0 && (
                      <Button
                        type="link"
                        style={{ margin: "0 8px" }}
                        onClick={prev}
                      >
                        {t("vdo_call_btn_back")}
                      </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                      <Button
                        style={{ marginLeft: "auto" }}
                        className="btn-primary"
                        type="primary"
                        loading={loading2}
                        onClick={sendLink}
                      >
                        Send Link
                      </Button>
                    )}
                  </div>
                </Space>
              </Card>
            </Form>
          </div>
        </div>
      );
    }
  };

  React.useEffect(() => {
    getUser();
    return () => { };
  }, []);

  const getUser = async () => {
    let user = await localStorage.getItem("claim");
    if (user) {
      user = JSON.parse(user);
      console.log("user::", user);
      setuserState(user);
      setphone(user.tel);
      setname(user.fristname + " " + user.lastname);
    }
  };

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      {userState.id && firstStep(currentStep)}
    </>
  );
};

export default Home;
