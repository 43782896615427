import axios from 'axios';
import { SUB_PATH, WEB_API } from '../env';
axios.defaults.baseURL = WEB_API;

axios.interceptors.response.use(response => {
    console.log("response", response);
    return response;
}, error => {
    if (error.response.status === 401) {
        window.location.href = SUB_PATH + '/';
    }
    throw error;
});