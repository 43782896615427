import React, { Component } from "react";
import {
  OTSession,
  OTStreams,
  preloadScript,
  createSession,
} from "opentok-react";
import { Redirect } from "react-router-dom";
import ConnectionStatus from "./ConnectionStatus";
import Publisher from "./Publisher";
import Subscriber from "./Subscriber";
import queryString from "query-string";
import CM from "../../utils/CM";
import axios from "axios";
import { Modal, Button, Space } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import "./index.scss";
import { SUB_PATH } from "../../env";

const MicrophoneImage = require("../../assets/images/microphone.png");
const MicrophoneImageClose = require("../../assets/images/microphone_close.png");
const CameraSwapImage = require("../../assets/images/camera_swap.png");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      connected: false,
      params: {},
      counter: 0,
      token: "",
      session_id: "",
      streams: [],
    };

    this.publisher = React.createRef();

    this.sessionEvents = {
      sessionConnected: () => {
        console.log("sessionConnected");
        this.setState({ connected: true });
      },
      sessionDisconnected: () => {
        this.setState({ connected: false });
      },
      connectionDestroyed: async () => {
        const { room_id } = this.state.params;
        try {
          const result = await axios({
            url: "opentok/room_disconnect/client/" + room_id,
            method: "get",
          });
          const { data } = result.data;
          //this.props.history.push(`/thankyou?time=${data.vdocalluse}&name=${data.custname}&room_id=${this.state.params.room_id}`);
          if (data.record_status === "I") {
            this.props.history.push(
              SUB_PATH + `/thankyou?room_id=${this.state.params.room_id}`
            );
          }
        } catch (error) {
          this.props.history.push(SUB_PATH + `/thankyou`);
        }
        // window.location.reload();
        // Modal.info({
        //     okText: "ปิด",
        //     content: 'เจ้าหน้าที่ได้ทำการปิดการสนทนาแล้ว',
        //     onOk: async () => {
        //         window.location.href = '/404';
        //     },
        // });
        // this.setState({ connected: false });
      },
    };
  }

  componentWillMount() {
    const { search } = this.props.location;
    let params = queryString.parse(search);
    this.setupBeforeUnloadListener();
    this.setState({ params: params });
  }

  async componentDidMount() {
    const { room_id, type } = this.state.params;
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/room/client/" + room_id,
        method: "get",
      });
      if (result.status === 200) {
        const { data } = result.data;
        const { token, session_id } = data;
        this.setState({ token: token, session_id: session_id });
      }
    } catch (error) {
      window.location.href = SUB_PATH + "/thankyou";
      // const { data, status } = error.response;
      // const { message } = data;
      // window.location.href = '/client';
      // Modal.error({
      //     okText: "ปิด",
      //     content: message,
      //     onOk: async () => {
      //         window.location.href = '/client';
      //     },
      // });
    }
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.doSomethingBeforeUnload();
    });
  };

  doSomethingBeforeUnload = () => {
    this.setState({ counter: 0 });
  };

  onError = (err) => {
    this.setState({ error: err });
  };

  subscriberConnected = () => {
    // if (this.state.counter >= 1) {
    //     window.location.href = "/full";
    //     return false;
    // } else {
    //     this.setState({ counter: this.state.counter + 1 });
    // }
  };

  subscriberDisConnected = () => {
    this.setState({ counter: 0 });
  };

  initPublisher = () => { };

  initSubscriber = () => { };

  componentWillUnmount() {
    if (this.sessionHelper) {
      this.sessionHelper.disconnect();
    }
  }

  endcallhandler = () => {
    if (this.sessionHelper) {
      this.sessionHelper.disconnect();
    }
  };

  render() {
    return (
      <>
        <div>
          {this.state.session_id === "" || this.state.token === "" ? null : (
            <OTSession
              apiKey={process.env.REACT_APP_API_KEY}
              sessionId={this.state.session_id}
              token={this.state.token}
              eventHandlers={this.sessionEvents}
              onError={this.onError}
            >
              {this.state.error ? <div>{this.state.error}</div> : null}
              {/* <ConnectionStatus connected={this.state.connected} /> */}
              <Publisher
                ref={this.publisher}
                token={this.state.token}
                initPublisher={this.initPublisher}
                hide={false}
              />
              <OTStreams>
                <Subscriber
                  params={this.state.params}
                  initSubscriber={this.initSubscriber}
                  subscriberDisConnected={this.subscriberDisConnected}
                  subscriberConnected={this.subscriberConnected}
                />
              </OTStreams>
            </OTSession>
          )}

          <div style={{ height: 20 }} />

          {/* <Button onClick={this.switchCamera} type={"default"} shape="circle" icon={<SwapOutlined />} size={"large"} /> */}
        </div>
      </>
    );
  }
}

export default preloadScript(App);
