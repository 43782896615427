import React, { useEffect, useState } from 'react';
import { Button, Modal, Checkbox } from 'antd';
import './index.scss';
import queryString from 'query-string';
import CM from '../../utils/CM';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { isMobile, isAndroid, isIOS, isSafari, isChrome } from 'react-device-detect';
import { SUB_PATH } from '../../env';

var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();

const HomeClient = (props) => {
    const { t, i18n } = useTranslation();
    const [consent, setconsent] = useState('');
    const [consentEN, setconsenten] = useState('');
    const [defaultLanguage, setDefaultLanguage] = useState('');
    const [isConsent, setIsConsent] = useState(false);
    const [acceptCondition, setAcceptCondition] = useState(false);
    const [acceptPolicy, setAcceptPolicy] = useState(false);
    const [room_default, setRoomDefault] = useState('')

    useEffect(() => {

        if (isMobile) {
            if (isAndroid) {
                if (!isChrome) {
                    // Modal.error({
                    //     okText: "ปิด",
                    //     content: "กรุณาเปิดด้วย Google Chrome",
                    //     onOk: async () => {
                    //         window.location.replace('404');
                    //     },
                    // });
                }
            } else if (isIOS) {
                if (!isSafari) {
                    Modal.error({
                        okText: "ปิด",
                        content: "กรุณาเปิดด้วย Safari",
                        onOk: async () => {
                            window.location.replace('404');
                        },
                    });
                }
            }
        }
        getConsent();
        getLanguage();
        const { search } = props.location;
        let params = queryString.parse(search);
        const { room_id } = params;
        getRoom(room_id);
        return () => {
        }
    }, []);

    const handleOk = e => {
        setIsConsent(true);
    };

    const getRoom = async (room_id) => {
        try {
            await CM.setHeaderAuth();
            const result = await axios({
                url: "opentok/room/client/" + room_id,
                method: "get",
            });
            if (result.status === 200) {
                setRoomDefault(room_id);
            }

        } catch (error) {
            setRoomDefault('');
            // const { data, status } = error.response;
            // const { message } = data;
            // window.location.href = '/client';
            // Modal.error({
            //     okText: "ปิด",
            //     content: message,
            //     onOk: async () => {
            //         window.location.href = '/client';
            //     },
            // });
        }
    }

    const handleCancel = e => {

    };

    const getConsent = async () => {
        try {
            await CM.setHeaderAuth();
            const result = await axios({
                url: "tran_content",
                method: "get",
            });

            if (result.status === 200) {
                const data = result.data;
                console.log(data);
                let consent = "";
                let consentEN = "";
                data.map((item, index) => {
                    consent += `${item.contentdesc ?? ""} <br/>`;
                    consentEN += `${item.contentdesc_en ?? ""} <br/>`;
                })
                setconsent(consent);
                setconsenten(consentEN)
            }

        } catch (error) {
            const { data, status } = error.response;
            // const { message } = data;
            // Modal.error({
            //     okText: "ปิด",
            //     content: message,
            //     onOk: async () => {
            //         // window.location.href = '/404';
            //     },
            // });
        }
    }

    const joinRoom = () => {
        const { search } = props.location;
        let params = queryString.parse(search);
        const { room_id } = params;
        // window.location.href = `/client/room?room_id=${room_id}`
        props.history.push(SUB_PATH + `/client/room?room_id=${room_id}`);
    }

    function consentHtml() {

        return { __html: consent };
    }

    const onChangeCheckBox = (e) => {
        setAcceptCondition(e.target.checked);
    }


    const onChangeCheckBoxPolicy = (e) => {
        setAcceptPolicy(e.target.checked);
    }

    const getLanguage = async () => {
        const language = await CM.getStorage('language');
        if (language === null || language === "") {
            setDefaultLanguage('th');
        } else {
            i18n.changeLanguage(language);
            setDefaultLanguage(language);
        }
    }

    const changeLanguage = async (type) => {
        await localStorage.setItem('language', type);
        i18n.changeLanguage(type);
        setDefaultLanguage(type);
    }


    return (
        <div style={{ height: '100%' }}>
            <div style={{ padding: 10, backgroundColor: '#fff', position: 'absolute', display: 'flex' }}>
                <div className={`cursor-pointer language-text ${defaultLanguage === "th" && 'active'}`} onClick={() => changeLanguage('th')}>ไทย</div>
                <div className={`cursor-pointer language-text ${defaultLanguage === "en" && 'active'}`} style={{ marginLeft: 10 }} onClick={() => changeLanguage('en')}>EN</div>
            </div>
            <div className="" style={{ height: (window.innerHeight / 2.2) }} />
            {room_default ?
                <div className="welcome-text-container">
                    <div className="text-center">
                        <label className="welcome-text-1">{t('welcome')}</label>
                    </div>
                    <div className="text-center">
                        <label className="welcome-text-2">{t('welcome_system')}</label>
                    </div>
                    <div className="text-center" style={{marginTop: -35, marginBottom: -20}}>
                        <label className="welcome-text-1">.....</label>
                    </div>
                    <div className="text-center">
                        <label className="welcome-text-3" style={{paddingRight: 25, paddingLeft: 25}}>{t('welcome_please_start')}</label>
                    </div>
                    <div className="text-center">
                        <label className="to-start-text">{t('start_video_text')}</label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button onClick={handleOk} className="text-center cursor-pointer" style={{ backgroundColor: '#ff5a1f', width: '80%', marginTop: 20, padding: 5, borderRadius: '25px', border: 0 }} type="success">
                            <label style={{ color: '#fff', fontWeight: 'bold', fontSize: '1.1rem' }}>{t('start_video_button')}</label>
                        </button>
                    </div>
                </div>
                : <div className="welcome-text-container">
                    <div className="text-center">
                        <label className="welcome-text-1">{t('thankyou')}</label>
                    </div>
                </div>
            }

            <Modal
                style={{ height: '100%' }}
                title={t('term_of_use')}
                visible={isConsent}
                onOk={joinRoom}
                onCancel={handleCancel}

                footer={[
                    <button disabled={(acceptCondition == true && acceptPolicy == true) ? false : true} onClick={joinRoom} className="text-center cursor-pointer" style={{ backgroundColor: (acceptCondition == true && acceptPolicy) ? '#28a745' : '#efefef', width: '80%', padding: 5, borderRadius: '0.5rem', border: 0 }} type="success">
                        <label style={{ color: '#fff', fontWeight: 'bold', fontSize: '1.1rem' }}>Next</label>
                    </button>,
                ]}
            // onCancel={handleCancel}
            >
                <div style={{ padding: 10, backgroundColor: '#fff', border: '1px solid #ababab', position: 'absolute', right: 0, display: 'flex', zIndex: 1000 }}>
                    <div className={`cursor-pointer language-text ${defaultLanguage === "th" && 'active'}`} onClick={() => changeLanguage('th')}>ไทย</div>
                    <div className={`cursor-pointer language-text ${defaultLanguage === "en" && 'active'}`} style={{ marginLeft: 10 }} onClick={() => changeLanguage('en')}>EN</div>
                </div>
                <div style={{ maxHeight: 400, overflow: 'auto', backgroundColor: '#F2F2F2', padding: 10 }}>
                    <div dangerouslySetInnerHTML={{
                        __html: defaultLanguage == "th" ? consent : consentEN
                    }} />
                </div>
                <div style={{ marginTop: 20 }}>
                    {/* <Checkbox >{t('accept_term')}</Checkbox> */}
                    <div style={{
                        display: 'flex',
                        gap: '0.5rem'
                    }}>
                        <input checked={acceptCondition} style={{
                            // height: 'fit-content',
                            height: 15,
                            width: 15
                        }} onChange={onChangeCheckBox} type="checkbox" name="consent" value="1" />
                        <div style={{ flex: 1 }}>
                            <label onClick={() => {
                                setAcceptCondition(acceptCondition ? false : true)
                            }} className=''>{t('accept_term')}</label>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <div style={{
                        display: 'flex',
                        gap: '0.5rem'
                    }}>
                        <input checked={acceptPolicy} style={{
                            height: 15,
                            width: 15
                        }} onChange={onChangeCheckBoxPolicy} type="checkbox" name="policy" value="2" />
                        <div style={{ flex: 1 }}>
                            <label onClick={() => {
                                setAcceptPolicy(acceptPolicy ? false : true)
                            }} className=''>{t('accept_policy')}</label>
                        </div>
                    </div>
                    {/* <Checkbox onChange={onChangeCheckBoxPolicy}>{t('accept_policy')}</Checkbox> */}
                </div>
            </Modal>
        </div>
    )
};

export default (HomeClient);
