import React, { Component } from "react";
import { Space, Col, Button } from "antd";
import { OTSubscriber } from "opentok-react";
import CheckBox from "./CheckBox";
import {
  PhoneOutlined,
  CameraOutlined,
  VideoCameraOutlined,
  CloseOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
//import { useTranslation } from "react-i18next";
const InsuranceCarImage = require("../../assets/images/insurance_car.png");
const InsuranceCarImageInActive = require("../../assets/images/insurance_car_inactive.png");
const PartiesCarImage = require("../../assets/images/parties_car.png");
const PartiesCarImageActive = require("../../assets/images/parties_car_active.png");
const AssetCarImage = require("../../assets/images/asset_car.png");
const AssetCarImageActive = require("../../assets/images/asset_car_active.png");
const MicrophoneImage = require("../../assets/images/microphone.png");
const MicrophoneImageClose = require("../../assets/images/microphone_close.png");

const ms = require("pretty-ms");
//const { t, i18n } = useTranslation();

export default class Subscriber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audio: true,
      video: true,
      publisherAudio: this.props.publisherAudio,
      camera_type: this.props.camera_type,
    };

    this.subscriber = React.createRef();

    this.subscriberEventHandlers = {
      error: (error) => {
        console.log(`There was an error with the subscriber: ${error}`);
      },
      connected: () => {
        console.log("subscriberEventHandlers Connected");
        this.props.subscriberConnected();
      },
      disconnected: () => {
        console.log("subscriberEventHandlers DisConnected");
        this.props.subscriberDisConnected();
      },
      destroyed: () => {
        console.log("subscriberEventHandlers Destroyed");
        this.props.subscriberDestroyed();
      },
    };
  }

  componentDidMount() {

  }

  getLangua

  setAudio = (audio) => {
    this.setState({ audio });
  };

  setVideo = (video) => {
    this.setState({ video });
  };

  onError = (err) => {
    alert(err.message);
    this.setState({ error: `Failed to subscribe: ${err.message}` });
  };

  setCamera = (type) => {
    this.props.takePhoto(type);
    this.setState({ camera_type: type });
  };

  render() {
    return (
      <>
        <div>
          <OTSubscriber
            ref={this.subscriber}
            properties={{
              subscribeToAudio: this.state.audio,
              subscribeToVideo: this.state.video,
              height: 450,
              width: 300,
              showControls: false,

            }}

            eventHandlers={this.subscriberEventHandlers}
            onError={this.onError}
            retry={true}
            maxRetryAttempts={1}
            retryAttemptTimeout={2000}
          />
          {this.props.children}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginLeft: 20,
          }}
        >
          <div>
            {/* <div>
              <Button type="danger" shape="circle" icon={<PhoneOutlined />} size={"large"} />
            </div> */}
            <div style={{ height: 10 }} />
            {/* {
              this.state.publisherAudio ?
                <img onClick={this.props.setAudioPublisher()} width={40} height={40} className="cursor-pointer" src={MicrophoneImage} alt="microphone" /> :
                <img onClick={this.props.setAudioPublisher()} width={40} height={40} className="cursor-pointer" src={MicrophoneImageClose} alt="microphone" />
            } */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: 20,
              paddingBottom: 30,
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <label className="text-white">
                {this.props.language == "th" ? "เลือกประเภทภาพถ่าย" : "Select type of photos"}
              </label>
            </div>
            <div>
              {this.state.camera_type == "insurance" ? (
                <img
                  onClick={() => this.setCamera("insurance")}
                  className="cursor-pointer"
                  height={50}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={InsuranceCarImage}
                  alt="microphone"
                />
              ) : (
                <img
                  onClick={() => this.setCamera("insurance")}
                  className="cursor-pointer"
                  height={50}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={InsuranceCarImageInActive}
                  alt="microphone"
                />
              )}
              <label style={{ marginLeft: 10 }} className="text-white">
                {this.props.language == "th" ? "ด้านหน้ารถยนต์" : "Front of the car"}
              </label>
            </div>
            <div style={{ height: 5 }} />
            <div>
              {this.state.camera_type == "parties" ? (
                <img
                  onClick={() => this.setCamera("parties")}
                  className="cursor-pointer"
                  height={40}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={InsuranceCarImage}
                  alt="microphone"
                />
              ) : (
                <img
                  onClick={() => this.setCamera("parties")}
                  className="cursor-pointer"
                  height={40}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={InsuranceCarImageInActive}
                  alt="microphone"
                />
              )}
              <label style={{ marginLeft: 10 }} className="text-white">

                {this.props.language == "th" ? "ด้านหลังรถยนต์" : "Rear of the car"}
              </label>
            </div>
            <div style={{ height: 10 }} />
            <div>
              {this.state.camera_type == "asset" ? (
                <img
                  onClick={() => this.setCamera("asset")}
                  className="cursor-pointer"
                  height={40}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={InsuranceCarImage}
                  alt="microphone"
                />
              ) : (
                <img
                  onClick={() => this.setCamera("asset")}
                  className="cursor-pointer"
                  height={40}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={InsuranceCarImageInActive}
                  alt="microphone"
                />
              )}
              <label style={{ marginLeft: 10 }} className="text-white">

                {this.props.language == "th" ? "ด้านข้างรถยนต์" : "Side of the car"}
              </label>
            </div>
            <div style={{ height: 10 }} />
            <div align="left">
              {this.state.camera_type == "numberasset" ? (
                <img
                  onClick={() => this.setCamera("numberasset")}
                  className="cursor-pointer"
                  height={40}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={InsuranceCarImage}
                  alt="microphone"
                />
              ) : (
                <img
                  onClick={() => this.setCamera("numberasset")}
                  className="cursor-pointer"
                  height={40}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={InsuranceCarImageInActive}
                  alt="microphone"
                />
              )}
              <label style={{ marginLeft: 10 }} className="text-white">

                {this.props.language == "th" ? "เลขตัวถัง" : "Chassis no."}
              </label>
            </div>
            <div style={{ height: 10 }} />
            <div align="left">
              {this.state.camera_type == "asset2" ? (
                <img
                  onClick={() => this.setCamera("asset2")}
                  className="cursor-pointer"
                  height={40}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={AssetCarImageActive}
                  alt="microphone"
                />
              ) : (
                <img
                  onClick={() => this.setCamera("asset2")}
                  className="cursor-pointer"
                  height={40}
                  width={40}
                  style={{ objectFit: "contain" }}
                  src={AssetCarImage}
                  alt="microphone"
                />
              )}
              <label style={{ marginLeft: 10 }} className="text-white">
                {this.props.language == "th" ? "อื่นๆ" : "Other"}
              </label>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//export default withTranslation()(Subscriber);
