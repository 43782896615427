import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from "axios";
import { Layout, Menu, Button, Row, Col } from "antd";
import Home from "./../home";
import Camera from "../../components/camera";
import MenuLayout from "../../components/menu";
import thaireeLogo from "../../assets/images/Easy_LOGO.png";
import { LogoutOutlined } from "@ant-design/icons";
import { SUB_PATH } from "../../env";
const HomeRoute = (props) => {
  const { Header, Content, Footer, Sider } = Layout;
  useEffect(() => {
    const { pathname } = props.location;
  }, []);

  const logout = async () => {
    await localStorage.clear();
    window.location.href = SUB_PATH + "/";
  };
  return (
    <React.Fragment>
      <Header>
        <div className="flex-container">
          <div>
            <img src={thaireeLogo} className="logo" />
          </div>
        </div>
      </Header>

      {props.location.pathname.includes("/home") ? (
        <Layout
          className="layout"
          style={{ height: "100vh", backgroundColor: "black" }}
        >
          <Content>
            <Row style={{ backgroundColor: "black" }}>
              <Col span={20} offset={3}>
                <Button
                  style={{ float: "right", marginTop: 20 }}
                  type="danger"
                  onClick={() => logout()}
                  key={"4"}
                  icon={<LogoutOutlined />}
                >
                  Log out
                </Button>
              </Col>
            </Row>
            <Layout
              className="site-layout-background"
              style={{ height: "100vh" }}
            >
              <Layout
                className="site-layout-background"
                style={{ padding: "0 24px 24px" }}
              >
                <Content
                  className="site-layout-background"
                  style={{
                    padding: 24,
                    margin: 0,
                  }}
                >
                  <Route
                    path={`${SUB_PATH}/home`}
                    render={({ match: { url, params } }) => (
                      <>
                        <Route
                          path={`${url}`}
                          params={params}
                          component={Home}
                          exact
                        />
                        <Route
                          path={`${url}/camera`}
                          params={params}
                          component={Camera}
                        />
                      </>
                    )}
                  />
                </Content>
              </Layout>
            </Layout>
          </Content>
        </Layout>
      ) : null}
      <div className="text-center" style={{ marginTop: 10 }}>
        <label className="text-white text-center">
          © 2022 Powered by BlueVenture Tech Co., Ltd.
        </label>
      </div>
    </React.Fragment>
  );
};

export default HomeRoute;
