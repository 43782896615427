import axios from "axios";
class CM {
  static async setHeaderAuth() {
    const token = await this.getStorage("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  static async getStorage(key) {
    const token = await localStorage.getItem(key);
    return token;
  }
}

export default CM;
