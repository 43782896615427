import React from 'react';
import { Result, Button } from 'antd';
const Page404 = () => {
    return (
        <div style={{ marginTop: '3rem' }}>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
            // extra={<Button onClick={() => {
            //     window.location.href = "/home";
            // }} type="primary">Back Home</Button>
            // }
            />
        </div>
    )

}

export default Page404;