import Swal from 'sweetalert2'

const SwalLoad = () => {
  Swal.fire({
    icon: "info",
    html: "Loading... Please Wait",
    willOpen: () => {
      Swal.showLoading();
    },
    showConfirmButton: false,
    allowOutsideClick: false,
  });
};
const SwalErr = (text) => {
  Swal.fire({
    icon: "error",
    html: text,
    customClass: {
      confirmButton: "btn btn-success",
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    confirmButtonText: "OK",
  });
};
const SwalSuccess = async (text) => {
  var res = await Swal.fire({
    icon: "success",
    html: text,
    customClass: {
      confirmButton: "btn-green",
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    confirmButtonText: "OK",
  });

  return res;
};

const SwalConfirm = async (text, language) => {
  var result = await Swal.fire({
    title: text,
    icon: "warning",
    confirmButtonText: language == "en" ? "Confirm" : "ยืนยัน",
    cancelButtonText: language == "en" ? "Cancel" : "ยกเลิก",
    reverseButtons: true,
    showCancelButton: true,
  });

  return result;
};

const SwalClose = () => {
  Swal.close();
};

const CSwl = {
  SwalLoad: SwalLoad,
  SwalErr: SwalErr,
  SwalClose: SwalClose,
  SwalSuccess: SwalSuccess,
  SwalConfirm: SwalConfirm,
};

export default CSwl;
