import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  Layout,
  Breadcrumb,
  Table,
  Space,
  message,
  Select,
  Modal,
  Form,
  DatePicker,
  Upload,
  Switch,
  Checkbox
} from "antd";
import {
  FileOutlined,
  FileImageOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./index.scss";
import axios from "axios";
import moment from "moment";
import CM from "../../utils/CM";
import { useHistory } from "react-router-dom";
import { SUB_PATH, WEB_FILE, WEB_VIEW, WEB_API } from "../../env";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

import { CheckFile } from "../../utils/uploadfile";
import SwalC from "../../configs/customSweetAlert";
import Swal from "sweetalert2";
import thaireeLogo from "../../assets/images/Easy_LOGO.png";
import { UploadOutlined } from "@ant-design/icons";
import CSwl from "../../configs/customSweetAlert";
import { async } from "q";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;

const { Header, Content } = Layout;
const { Meta } = Card;

const template = require("../../templates/import_customer_template.xlsx");


const VdoCallHistory = (props) => {
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [form] = Form.useForm();
  const [form_insurer] = Form.useForm();
  const [form_comment] = Form.useForm();
  // const user = await localStorage.getStorage("user");
  const [roleState, setRoleState] = useState("");

  useEffect(() => {
    func_set_role_state();
  }, []);

  useEffect(() => {
    console.log("roleState::", roleState);
    setStatus((roleState && roleState == "AD") ? "U" : "A");
  }, [roleState]);

  const renderStatus = (userRole) => {
    if (userRole == "U") {
      userRole = t("unassigned");
    } else if (userRole == "W") {
      userRole = t("waiting");
    } else if (userRole == "R") {
      userRole = t("reopen");
    } else if (userRole == "O") {
      userRole = t("onprocess");
    } else if (userRole == "C") {
      userRole = t("completed");
    } else if (userRole == "CN") {
      userRole = t("canceled");
    } else if (userRole == "SI") {
      userRole = t("send_insurer");
    } else if (userRole == "RJ") {
      userRole = t("reject_data");
    } else if (userRole == "RC") {
      userRole = t("reject_car");
    } else if (userRole == "AP") {
      userRole = t("approve");
    }

    return userRole;
  }

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [countSelect, setCountSelect] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [phone, setPhone] = useState("");
  const [roomId, setRoomId] = useState("");
  const [dataSelected, setDataSelected] = useState({});
  const createState = {
    fristname: "",
    lastname: "",
    email: "",
    tel: "",
    brand: "",
    model: "",
    quotation_no: "",
    plate_no: "",
    province: "",
    jlo_ref: "",
    crm_ref: "",
    accident_date: null,
  };
  const insurerCreateState = {
    id: 0,
    insurer: "",
  };

  const { t, i18n } = useTranslation();

  const func_changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const gotoClaim = async (record) => {
    await localStorage.setItem("claim", JSON.stringify(record));
    history.push(SUB_PATH + "/home");
  }

  const onCheck = (checked, record) => {
    var selected = [...task_selected];
    if (checked == true) {
      selected.push(record);
    } else {
      var index = selected.findIndex((x) => x.id == record.id);
      selected.splice(index, 1);
    }
    set_task_selected(selected);
  }

  const onCheckAssign = (checked, record) => {
    var selected = [...agent_selected];
    if (checked == true) {
      selected.push(record);
    } else {
      var index = selected.findIndex((x) => x.agent_id == record.agent_id);
      selected.splice(index, 1);
    }
    set_agent_selected(selected);
  }

  const [callState, setCallState] = useState({ ...createState });
  const [insurerState, setInsurerState] = useState({ ...insurerCreateState });
  const [exportState, setExportState] = useState({
    fromDate: null,
    toDate: null
  });
  const columns = [
    {
      title: t("no"),
      dataIndex: "order",
    },
    {
      title: "",
      dataIndex: "select",
      render: (text, record) => (
        <>
          {(record.sys_status == "W" || record.sys_status == "R" || record.sys_status == "U") && roleState == "AD" && (
            <Checkbox checked={task_selected.some((x) => x.id == record.id)} onChange={(e) => onCheck(e.target.checked, record)}></Checkbox>
          )}
        </>
      ),
    },
    // {
    //   title: t("ref_no"),
    //   dataIndex: "ref_no",
    // },
    {
      title: t("create_date"),
      dataIndex: "create_date",
      render: (text, record) => (
        <td>
          {record.create_date ? moment(record.create_date).format("DD/MM/YYYY HH:mm") : ""}
        </td>
      ),
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (text, record) => (
        <td>{record.fristname + " " + record.lastname}</td>
      ),
    },
    // {
    //   title: t("email"),
    //   dataIndex: "email",
    //   render: (text, record) => <td>{record.email ?? ""}</td>,
    // },
    {
      title: t("phone_no"),
      dataIndex: "tel",
    },
    {
      title: t("brand"),
      dataIndex: "brand",
    },
    {
      title: t("model"),
      dataIndex: "model",
    },
    // {
    //   title: t("quotation_no"),
    //   dataIndex: "quotation_no",
    // },
    {
      title: t("plate_no"),
      dataIndex: "plate_no",
    },
    {
      title: t("province"),
      dataIndex: "province",
    },
    {
      title: t("insurer"),
      dataIndex: "insurer",
    },
    {
      title: t("jlo_ref"),
      dataIndex: "jlo_ref",
    },
    {
      title: t("crm_ref"),
      dataIndex: "crm_ref",
    },
    {
      title: t("status"),
      dataIndex: "sys_status",
      render: (text, record) => (
        <td>
          <div>{renderStatus(record.sys_status)}</div>
        </td>
      ),
    },
    {
      title: t("refer_user"),
      dataIndex: "refer_user",
      render: (text, record) => (
        <td>
          <div>{record.refer_user ?? ""}</div>
        </td>
      ),
    },
    {
      title: t("update_date"),
      dataIndex: "update_date",
      render: (text, record) => (
        <td>
          {record.update_date ? moment(record.update_date).format("DD/MM/YYYY HH:mm") : ""}
        </td>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <>
          {(record.sys_status == "W" || record.sys_status == "R") && (roleState == "AG" || roleState == "AD") && (
            <Button onClick={() => {
              gotoClaim(record);
            }} size="middle">
              {t("appoinment")}
            </Button>
          )}
          {record.sys_status == "O" && (roleState == "AG" || roleState == "AD") && (
            <Button onClick={async () => {
              func_updateStatus(record, "C")
              // 
            }} size="middle">
              {t("btn_complete")}
            </Button>
          )}
          {record.sys_status == "O" && (roleState == "AG" || roleState == "AD") && (
            <Button onClick={async () => {
              func_updateStatus(record, "CN")
              // 
            }} size="middle">
              {t("btn_cancel")}
            </Button>
          )}
          {(record.sys_status == "C" || record.sys_status == "CN" || record.sys_status == "O" 
          || record.sys_status == "SI" || record.sys_status == "RJ"
          || record.sys_status == "AP" || record.sys_status == "RC") && (
            <Button
              onClick={() => func_viewImageAndVideo(record)}
              size="middle"
            >
              Gallery
            </Button>
          )}
          {((record.sys_status == "C" || record.sys_status == "CN" || record.sys_status == "RJ") && (roleState == "AG" || roleState == "AD")) && (
            <Button onClick={() => {

              func_updateStatus(record, "R")
              // 
            }} size="middle">
              {t("btn_reopen")}
            </Button>
          )}
          {((record.sys_status == "C" || record.sys_status == "RJ") && (roleState == "AG")) && (
            <Button onClick={() => {

              func_updateStatus(record, "SI")
              // 
            }} size="middle">
              {t("btn_send_to_insurer")}
            </Button>
          )}
          {(record.sys_status == "SI" && (roleState == "IN")) && (
            <Button onClick={() => {

              func_updateStatus(record, "AP")
              // 
            }} size="middle">
              {t("btn_approve")}
            </Button>
          )}
          {(record.sys_status == "SI" && (roleState == "IN")) && (
            <Button onClick={() => {
              set_data_selected(record);
              set_comment("");
              setModalComment(true);
              // 
            }} size="middle">
              {t("btn_reject")}
            </Button>
          )}
          {(record.sys_status == "SI" && (roleState == "IN")) && (
            <Button onClick={() => {

              func_updateStatus(record, "RC")
              // 
            }} size="middle">
              {t("btn_reject_car")}
            </Button>
          )}
          {/* {roleState == "AD" && (
            <Button
              type="danger"
              className="btn-red"
              onClick={() => {
                func_delete(record)
              }} size="middle"
            >
              {t("btn_delete")}
            </Button>
          )} */}
        </>
      ),
    },
  ];
  const columnsAgent = [
    {
      title: t("no"),
      dataIndex: "order",
    },
    {
      title: t("fullname"),
      dataIndex: "fullname",
    },
    {
      title: t("useraccount"),
      dataIndex: "useraccount",
    },
    {
      title: t("agent_job_cnt"),
      dataIndex: "cnt",
    },
    {
      title: t("is_shift"),
      dataIndex: "is_shift",
      render: (text, record) => (
        <td>
          <Switch checked={record.is_shift ?? false} onChange={(e) => func_updateAgentStatus(record, !(record.is_shift ?? false))} />
        </td>
      ),
    },
  ];
  const columnsInsurer = [
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <td>
          <Space direction="horizontal">
            <Button
                onClick={() => {
                  onDeleteInsurer(record);
                }}
                type="danger"
                icon={<DeleteOutlined />}
            >
                {/* {t("btn_delete")} */}
            </Button>
          </Space>
        </td>
      ),
    },
    // {
    //   title: t("no"),
    //   dataIndex: "order",
    // },
    {
      title: t("insurer"),
      dataIndex: "insurer",
    },
  ];
  const columnsReAssign = [
    {
      title: t("no"),
      dataIndex: "order",
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (<Checkbox checked={agent_selected.some((x) => x.agent_id == record.agent_id)} onChange={(e) => onCheckAssign(e.target.checked, record)}></Checkbox>
      ),
    },
    {
      title: t("fullname"),
      dataIndex: "fullname",
    },
    {
      title: t("useraccount"),
      dataIndex: "useraccount",
    },
    {
      title: t("agent_job_cnt"),
      dataIndex: "cnt",
    },
  ];
  const columnsComment = [
    {
      title: t("no"),
      dataIndex: "order",
    },
    {
      title: t("comment"),
      dataIndex: "comment",
    },
    {
      title: t("comment_date"),
      dataIndex: "create_date",
      render: (text, record) => (
        <td>
          {record.create_date ? moment(record.create_date).format("DD/MM/YYYY HH:mm") : ""}
        </td>
      ),
    },
  ];
  const [datatable, setdatatable] = useState([]);
  const [agentdatatable, setagentdatatable] = useState([]);
  const [insurerdatatable, setinsurerdatatable] = useState([]);
  const [commentdatatable, setcommentdatatable] = useState([]);
  const [status, setStatus] = useState("A");
  const [search, setSearch] = useState("");
  const [insurer, setInsurer] = useState("");
  const [searchAgent, setSearchAgent] = useState("");
  const [modalvisibleimageandvideo, setModalvisibleimageandvideo] = useState(false);
  const [modalagent, setModalagent] = useState(false);
  const [modalinsurer, setModalInsurer] = useState(false);
  const [modalReAssign, setModalReAssign] = useState(false);
  const [modalComment, setModalComment] = useState(false);
  const [task_selected, set_task_selected] = useState([]);
  const [agent_selected, set_agent_selected] = useState([]);
  const [data_selected, set_data_selected] = useState({});
  const [comment, set_comment] = useState("");

  const func_set_role_state = async () => {
    var role = await localStorage.getItem("role");
    console.log("role::", role);
    setRoleState(role);
    if (role == "IN") {
      var userstr = await localStorage.getItem("user");
      var user = JSON.parse(userstr);
      console.log("user::", user);
      setInsurer(user.insurer)
    }
  };

  const logout = async () => {
    await localStorage.clear();
    window.location.href = SUB_PATH + "/";
  };

  const func_updateStatus = async (record, status) => {
    //await localStorage.setItem("claim", JSON.stringify(record));
    if (status == "CN" || status == "R" || status == "C" || status == "SI" || status == "RC" || status == "RJ" || status == "AP") {
      var textConfirm = ""
      if (status == "CN") {
        textConfirm = t("confirm_cancel")
      } else if (status == "R") {
        textConfirm = t("confirm_reopen")
      } else if (status == "C") {
        textConfirm = t("confirm_complete")
      } else if (status == "SI") {
        textConfirm = t("confirm_sent_insurer")
      } else if (status == "RC") {
        textConfirm = t("confirm_reject_car")
      } else if (status == "AP") {
        textConfirm = t("confirm_approve")
      } else if (status == "RJ") {
        textConfirm = t("confirm_reject")
      }
      SwalC.SwalConfirm(textConfirm).then(async (result) => {
        if (result.isConfirmed) {
          await CM.setHeaderAuth();
          const result = await axios({
            url: "/backoffice/trancall/updatestatus",
            method: "post",
            data: { id: record.id, status: status, comment: comment },
          });
          if (result.status === 200) {
            func_loadData();
            if (status == "RJ") {
              set_comment("");
              setModalvisibleimageandvideo(false);
            }
          }
        }
      });
    } else {
      if (status == "O") {
        await localStorage.setItem("claim", JSON.stringify(record));
        history.push(SUB_PATH + "/home");
      } else {
        await CM.setHeaderAuth();
        const result = await axios({
          url: "/backoffice/trancall/updatestatus",
          method: "post",
          data: { id: record.id, status: status, comment: comment },
        });
        if (result.status === 200) {
          func_loadData();
        }
      }
    }
  };

  const func_delete = async (record, status) => {
    SwalC.SwalConfirm(t("confirm_delete")).then(async (result) => {
      if (result.isConfirmed) {
        await CM.setHeaderAuth();
        const result = await axios({
          url: "/backoffice/trancall/delete",
          method: "delete",
          data: { id: record.id, status: status },
        });
        if (result.status === 200) {
          func_loadData();
        }
      }
    });
  };

  const func_updateAgentStatus = async (record, status) => {
    await CM.setHeaderAuth();
    const result = await axios({
      url: "/backoffice/userlicensereserve",
      method: "put",
      data: { id: record.agent_id, is_shift: status },
    });
    if (result.status === 200) {
      func_loadAgentData();
    }
  };

  const [modalvisibleimage, setmodalvisibleimage] = useState(false);
  const [dataImage, setDataImage] = useState({ insurance: [] });

  const [modalvisiblevdo, setmodalvisiblevdo] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const [modalCreateInsurer, setModalCreateInsurer] = useState(false);

  const handleCancelimage = (e) => {
    setmodalvisibleimage(false);
  };

  const handleCancelcreate = (e) => {
    setModalCreate(false);
  };

  const handleCancelcreateInsurer = (e) => {
    setModalCreateInsurer(false);
  };

  const handleCancelimageandvdeo = (e) => {
    setModalvisibleimageandvideo(false);
  };

  const handleCancelvdo = (e) => {
    setmodalvisiblevdo(false);
  };

  const func_selectAll = (status) => {
    var dataV = [...Vdorecord];
    var dataI = { ...dataImage };
    for (var i = 0; i < dataV.length; i++) {
      dataV[i].checked = status;
    }
    setVdorecord(dataV);
    setIsSelectAll(status);

    if (dataI.insurance) {
      for (var i = 0; i < dataI.insurance.length; i++) {
        dataI.insurance[i].checked = status;
      }
    }

    if (dataI.parties) {
      for (var i = 0; i < dataI.parties.length; i++) {
        dataI.parties[i].checked = status;
      }
    }

    if (dataI.asset) {
      for (var i = 0; i < dataI.asset.length; i++) {
        dataI.asset[i].checked = status;
      }
    }

    if (dataI.asset2) {
      for (var i = 0; i < dataI.asset2.length; i++) {
        dataI.asset2[i].checked = status;
      }
    }

    if (dataI.numberasset) {
      for (var i = 0; i < dataI.numberasset.length; i++) {
        dataI.numberasset[i].checked = status;
      }
    }

    func_countSelect();
  };

  const func_countSelect = () => {
    var dataV = [...Vdorecord];
    var dataI = { ...dataImage };
    var count = 0;
    for (var i = 0; i < dataV.length; i++) {
      if (dataV[i].checked) {
        count++;
      }
    }

    if (dataI.insurance) {
      for (var i = 0; i < dataI.insurance.length; i++) {
        if (dataI.insurance[i].checked) {
          count++;
        }
      }
    }

    if (dataI.parties) {
      for (var i = 0; i < dataI.parties.length; i++) {
        if (dataI.parties[i].checked) {
          count++;
        }
      }
    }

    if (dataI.asset) {
      for (var i = 0; i < dataI.asset.length; i++) {
        if (dataI.asset[i].checked) {
          count++;
        }
      }
    }

    setCountSelect(count);
  };

  const download = (dataurl, filename) => {
    // Construct the <a> element
    var link = document.createElement("a");
    link.href = dataurl;
  };

  const func_loadAll = () => {
    var dataV = [...Vdorecord];
    var dataI = { ...dataImage };
    var count = 0;
    for (var i = 0; i < dataV.length; i++) {
      if (dataV[i].checked) {
        count++;
      }
    }

    if (dataI.insurance) {
      for (var i = 0; i < dataI.insurance.length; i++) {
        if (dataI.insurance[i].checked) {
          count++;
          var p =
            WEB_FILE + dataI.insurance[i].imagepath.replace("./public", "");
          var filename = dataI.insurance[i].imagepath.split("/");
          filename = filename[filename.length - 1];
          download(p, filename);
        }
      }
    }

    if (dataI.parties) {
      for (var i = 0; i < dataI.parties.length; i++) {
        if (dataI.parties[i].checked) {
          count++;
        }
      }
    }

    if (dataI.asset) {
      for (var i = 0; i < dataI.asset.length; i++) {
        if (dataI.asset[i].checked) {
          count++;
        }
      }
    }
  };

  const func_viewvideo = async (record) => {
    var room_id = record.sysref;
    await CM.setHeaderAuth();
    const result = await axios({
      url: `/backoffice/tran_vdocall_record?room_id=${room_id}`,
      method: "get",
    });
    if (result.status === 200) {
      const { data } = result;
      setVdorecord(data);
      setmodalvisiblevdo(true);
    }
  };

  function fancyTimeFormat(duration) {
    // minutes and seconds
    var mins = ~~(duration / 60);
    return mins;
  }

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const [Vdorecord, setVdorecord] = useState([]);

  useEffect(() => {
    func_countSelect();
  }, [Vdorecord, dataImage]);
  const columnsVdo = [
    {
      title: t("duration"),
      dataIndex: "duration",
      render: (text, record) => {
        return fancyTimeFormat(text) ? fancyTimeFormat(text) : 1;
      },
    },
    {
      title: t("file_size"),
      dataIndex: "size",
      render: (text) => `${bytesToSize(text)}`,
    },
    {
      title: "VDO",
      dataIndex: "video_id",
      render: (text, record) => (
        <button
          className="btn-blue"
          onClick={() => {
            window.open(
              record.url,
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        >
          {t("open_vdo")}
        </button>
      ),
    },
  ];

  const func_viewimage = async (record) => {
    var room_id = record.sysref;
    await CM.setHeaderAuth();
    const result = await axios({
      url: `/backoffice/tran_vdocall_image?room_id=${room_id}`,
      method: "get",
    });
    if (result.status === 200) {
      const { data } = result;
      setmodalvisibleimage(true);
      setDataImage(data);
      console.log(data);
    }
  };

  const func_viewImageAndVideo = async (record) => {
    setDataSelected(record)
    var room_id = record.ref_no;
    setPhone(record.tel);
    setRoomId(room_id);
    await CM.setHeaderAuth();
    const result = await axios({
      url: `/backoffice/tran_vdocall_image?room_id=${record.ref_no}`,
      method: "get",
    });
    if (result.status === 200) {
      const { data, comment } = result;
      for (let i = 0; i < data.length; i++) {
        data[i].checked = false;
      }
      for (let i = 0; i < data.comment.length; i++) {
        data.comment[i].order = i+1;
      }

      setDataImage(data);
      setcommentdatatable(data.comment);
      console.log(data);
    }

    var room_id = record.ref_no;
    await CM.setHeaderAuth();
    const result2 = await axios({
      url: `/backoffice/tran_vdocall_record?room_id=${record.ref_no}`,
      method: "get",
    });
    if (result2.status === 200) {
      const { data } = result2;
      for (let i = 0; i < data.length; i++) {
        data[i].checked = false;
      }
      setVdorecord(data);
    }

    setModalvisibleimageandvideo(true);
  };

  const func_loadData = async () => {
    await CM.setHeaderAuth();
    const result = await axios({
      url: "/backoffice/trancall/getlist",
      method: "post",
      data: {
        sys_status: status,
        search: search,
        insurer: insurer,
      },
    });
    if (result.status === 200) {
      const { data } = result;
      for (var i = 0; i < data.length; i++) {
        data[i].order = i + 1;
      }
      console.log(data);
      setdatatable(data);
    }
  };


  const getLanguage = async () => {
    const language = await CM.getStorage('language');
    if (language === null || language === "") {
      setDefaultLanguage('th');
    } else {
      i18n.changeLanguage(language);
      setDefaultLanguage(language);
    }
  }
  const func_loadAgentData = async () => {
    await CM.setHeaderAuth();
    const result = await axios({
      url: "/backoffice/useragent",
      method: "post",
      data: {
        search: searchAgent
      }
    });
    if (result.status === 200) {
      const { data } = result;
      for (var i = 0; i < data.length; i++) {
        data[i].order = i + 1;
      }
      console.log(data);
      setagentdatatable(data);
    }
  };
  const func_loadInsurerData = async () => {
    await CM.setHeaderAuth();
    const result = await axios({
      url: "/backoffice/insurer",
      method: "get",
    });
    if (result.status === 200) {
      const { data } = result;
      for (var i = 0; i < data.length; i++) {
        data[i].order = i + 1;
      }
      console.log(data);
      setinsurerdatatable(data);
    }
  };

  useEffect(() => {
    func_loadData();
    func_loadAgentData();
    func_loadInsurerData();
    // console.log("user::", user);
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await CM.setHeaderAuth();
      const result = await axios({
        url: "backoffice/trancall/create",
        method: "post",
        data: callState,
      });
      setLoading(false);
      if (result.status === 200) {
        func_loadData();
        setModalCreate(false);
        setCallState({ ...createState });
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      setLoading(false);
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
  };

  const onFinishInsurer = async (values) => {
    try {
      setLoading(true);
      await CM.setHeaderAuth();
      const result = await axios({
        url: "backoffice/insurer",
        method: insurerState.id == 0 ? "post" : "put",
        data: insurerState,
      });
      setLoading(false);
      console.log("result::", result);
      if (result.status === 200) {
        func_loadInsurerData();
        setModalCreateInsurer(false);
        setInsurerState({ ...insurerCreateState });
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      setLoading(false);
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
  };

  const onFinishComment = async (values) => {
    try {
      setLoading(true);
      await CM.setHeaderAuth();
      await CM.setHeaderAuth();
      const result = await axios({
        url: "/backoffice/trancall/updatestatus",
        method: "post",
        data: { id: data_selected.id, status: "RJ", comment: comment },
      });
      if (result.status === 200) {
        set_comment("");
        setModalComment(false);
        set_data_selected({});
        func_loadData();
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      setLoading(false);
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
  };

  const func_createlink = async () => {
    try {
      await CM.setHeaderAuth();
      var link = WEB_VIEW;
      var ba = btoa(roomId);
      link = `${link}?q=${ba}`;
      console.log("ba::", ba);
      console.log("atob(ba)::", atob(ba));
      console.log("link::", link);
      const result = await axios({
        url: "opentok/send_view_gallery",
        method: "post",
        data: { phone: phone, link: link, ref: dataSelected.crm_ref },
      });
      if (result.status === 200) {
        const { data, message } = result.data;

        Modal.success({
          okText: t("complete"),
          content: t("success_send"),
          onOk: () => {
            //window.location.href = "/home/camera?room_id=" + room_id;
          },
        });
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
  };

  const func_resetstate = () => {
    setCallState({ ...createState });
    form.resetFields();
    //this.props.form.resetFields();
    /*fnameinput.current.value = "";
    lnameinput.current.value = "";
    emailinput.current.value = "";
    phoneinput.current.value = "";*/
    //console.log(document.getElementById("fristname").value);
    /*document.getElementById("phoneinput").innerHTML = "";
    document.getElementById("email").innerHTML = "";
    document.getElementById("lastname").innerHTML = "";*/
  };

  useEffect(() => {
    console.log("insurerState::", insurerState);
  }, [insurerState]);

  useEffect(() => {
    console.log("status::", status);
    func_loadData();
  }, [status, insurer]);

  const fileprops = {
    beforeUpload: (file) => {
      return CheckFile({
        file,
        size: 10,
        type: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
        message: `รองรับเฉพาะไฟล์ประเภท xlsx`,
      });
    },
    name: "file",
    multiple: false,
    action: `${WEB_API}backoffice/trancall/upload`,
    onChange(info) {
      Swal.showLoading();
      console.log("info::", info);
      if (info.file.status === "done") {
        func_loadData();
        const response = info.file.response;
        var ids = JSON.parse(response.ids);
        var selected = [];
        for (let i = 0; i < ids.length; i++) {
          var id = ids[i];
          selected.push({
            id: id
          });
        }
        set_task_selected(selected);
        func_loadAgentData();
        setModalReAssign(true);

        Swal.close();
      } else if (info.file.status === "error") {
        console.log("info.file::", info.file);
        Swal.close();
        var message = info.file.response.message;
        if (!info.file.response) {
          message = info.file.response
        }
        Swal.fire({
          icon: "error",
          title: message,
        });
      }
    },
  };

  const func_submit_reassign = async (e) => {
    e.preventDefault();
    if (agent_selected.length == 0) {
      Swal.fire({
        icon: "error",
        title: t("v_select_agent"),
      });
      return false;
    }

    await CM.setHeaderAuth();
    const result = await axios({
      url: "/backoffice/trancall/reassign",
      method: "post",
      data: {
        tasks: JSON.stringify(task_selected.map((x) => x.id)),
        agents: JSON.stringify(agent_selected.map((x) => x.agent_id))
      },
    });
    if (result.status === 200) {
      set_agent_selected([]);
      set_task_selected([]);
      SwalC.SwalSuccess("SUCCESS!!");
      setModalReAssign(false);
      func_loadData();
    }

  }

  const func_copy = async () => {
    try {
      Swal.showLoading();
      await CM.setHeaderAuth();
      var link = WEB_VIEW;
      var ba = btoa(roomId);
      link = `${link}?q=${ba}`;
      navigator.clipboard.writeText(link);
      Swal.close();
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
  };

  const handleExport = async () => {
    Swal.showLoading();
    let result = {};
    try {
      await CM.setHeaderAuth();
      result = await axios({
        url: "/backoffice/tran_vdocall/export",
        method: "post",
        data: exportState,
      });

      if (result.status === 200) {
        Swal.close();
        const provinces = result.data;
        const link = document.createElement('a');
        link.href = provinces;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setModalExport(false);
      }
    } catch (error) {
      Swal.close();
      Modal.error({
        okText: "ปิด",
        content: "ส่งข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
        onOk: () => {
        },
      });
    }
  };

  const onDeleteInsurer = (record) => {
      Modal.confirm({
          title: "ลบข้อมูล",
          icon: <ExclamationCircleOutlined />,
          content: "ต้องการลบข้อมูลชุดนี้ ใช่หรือไม่",
          okText: "ตกลง",
          onOk: async () => {
              const itemdeleted = record;
              try {
                  await CM.setHeaderAuth();
                  Swal.showLoading();
                  const result = await axios({
                      url: "/backoffice/insurer/delete",
                      method: "put",
                      data: itemdeleted,
                  });
                  if (result.status === 200) {
                      Swal.close();
                      Modal.success({
                        okText: t("complete"),
                        content: t("success_delete"),
                        onOk: () => {
                          func_loadInsurerData();
                        },
                      });
                  }
              } catch (error) {
                Swal.close();
                Modal.error({
                  okText: "ปิด",
                  content: "ส่งข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
                  onOk: () => {
                  },
                });
              }
          },
          cancelText: "ยกเลิก",
      });
  };


  return (
    <React.Fragment>
      <Header>
        <div className="flex-container">
          <div>
            <img src={thaireeLogo} className="logo" />
          </div>
        </div>
      </Header>
      <div
        style={{
          margin: "0px",
          padding: "0 50px",
          height: "100vh",
          backgroundColor: "#eeeeee",
        }}
      >
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ marginTop: 25, marginLeft: "auto" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{ marginLeft: "5px", fontWeight: i18n.language == "th" ? "bold" : "normal" }}
                onClick={() => {
                  func_changeLanguage("th");
                }}
              >
                th
              </div>
              <div style={{ marginLeft: "5px" }}>/</div>
              <div
                style={{ marginLeft: "5px", fontWeight: i18n.language == "en" ? "bold" : "normal" }}
                onClick={() => {
                  func_changeLanguage("en");
                }}
              >
                en
              </div>
            </div>
          </div>

          <button
            style={{ marginTop: 20, marginLeft: "20px" }}
            type="danger"
            className="btn-red"
            onClick={() => logout()}
            key={"4"}
          >
            <div className="flex">
              <div style={{ marginRight: "6px" }}>
                <LogoutOutlined />
              </div>
              <div>
                <span>Log out</span>
              </div>
            </div>
          </button>
        </div>
        <Card
          className="card-box-c"
          title={t("appoinment_list")}
          bordered={false}
        >
          <Row style={{ marginBottom: "10px" }}>
            <Col span={14}>
              <Row>
                <div className="flex">
                  <div style={{ padding: "10px" }}>{t("status")}</div>
                  <div style={{ padding: "10px" }}>
                    <Select
                      style={{ width: "200px" }}
                      onChange={(v) => {
                        setStatus(v);
                      }}
                      value={status}
                    >
                      <Option value="A">{t("all")}</Option>
                      <Option value="U">{t("unassigned")}</Option>
                      <Option value="TD">{t("todo")}</Option>
                      <Option value="O">{t("onprocess")}</Option>
                      <Option value="C">{t("completed")}</Option>
                      <Option value="CN">{t("canceled")}</Option>
                      <Option value="SI">{t("send_insurer")}</Option>
                      <Option value="RJ">{t("reject_data")}</Option>
                      <Option value="RC">{t("reject_car")}</Option>
                      <Option value="AP">{t("approve")}</Option>
                    </Select>
                  </div>
                  <div style={{ padding: "10px" }}>{t("label_search")}</div>
                  <div style={{ padding: "10px" }}>
                    <Input
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ padding: "10px" }}>{t("insurer")}</div>
                  <div style={{ padding: "10px" }}>
                    <Select
                      style={{ width: "200px" }}
                      onChange={(v) => {
                        setInsurer(v);
                      }}
                      value={insurer}
                      disabled={roleState == "IN"}
                    >
                      <Option value="">{t("all")}</Option>
                      {insurerdatatable.map((x) => (
                        <Option value={x.insurer}>{x.insurer}</Option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <button
                      className="btn-blue"
                      onClick={() => {
                        func_loadData();
                      }}
                    >
                      {t("search")}
                    </button>
                  </div>
                </div>
              </Row>
              <Row>
                <div className="flex">
                  {roleState == "AD" && (
                    <div style={{ padding: "10px" }}>
                      <button
                        className="btn-blue"
                        type="button"
                        onClick={() => {
                          set_task_selected(datatable.filter((x) => x.sys_status == "W" || x.sys_status == "R" || x.sys_status == "U"))
                        }}
                      >
                        {t("btn_select_all")}
                      </button>
                    </div>
                  )}
                </div>
              </Row>
            </Col>
            <Col span={10}>
              <Row>
                <div className="flex" style={{ width: "100%" }}>
                  <div style={{ padding: "10px", marginLeft: "auto", textAlign: "right" }}>
                    {roleState == "AD" && (
                      <a
                        href={template}
                      >
                        <button
                          key="submit"
                          className="btn-green"
                          style={{ marginRight: "6px", cursor: "pointer" }}
                        >
                          <div className="flex">
                            <span>{t("btn_download_template")}</span>
                          </div>
                        </button>
                      </a>
                    )}
                    {roleState == "AD" && (
                      <Upload
                        {...fileprops}
                        maxCount={1}
                        showUploadList={false}
                        id="file"
                      >
                        <button
                          key="submit"
                          className="btn-green"
                          style={{ marginRight: "6px", cursor: "pointer" }}
                        >
                          <div className="flex">
                            <div>
                              <UploadOutlined className="mr-2" />
                              <span>{t("import_new_appoinment")}</span>
                            </div>
                          </div>
                        </button>
                      </Upload>
                    )}
                    {(roleState == "AD" || roleState == "AG") && (
                      <button
                        key="submit"
                        className="btn-green"
                        onClick={() => {
                          setModalCreate(true);
                          func_resetstate();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="flex">
                          <div style={{ marginRight: "6px" }}>
                            <PlusOutlined />
                          </div>
                          <div>
                            <span>{t("create_a_new_appoinment")}</span>
                          </div>
                        </div>
                      </button>
                    )}
                    </div>
                </div>
              </Row>
              <Row>
                <div className="flex" style={{ width: "100%" }}>
                  <div style={{ padding: "10px", marginLeft: "auto", textAlign: "right" }}>
                    {roleState == "AD" && (
                      <button
                        key="submit"
                        className="btn-green"
                        style={{ marginLeft: "6px", cursor: "pointer" }}
                        onClick={() => {
                          if (task_selected.length == 0) {
                            Swal.fire({
                              icon: "error",
                              title: t("v_select_task"),
                            });
                            return false;
                          }
                          func_loadAgentData();
                          setModalReAssign(true);
                        }}
                      >
                        <span>{t("btn_assign")}</span>
                      </button>
                    )}
                    {roleState == "AD" && (
                      <button
                        key="submit"
                        className="btn-green"
                        style={{ marginLeft: "6px", cursor: "pointer" }}
                        onClick={() => {
                          func_loadAgentData();
                          setModalagent(true);
                        }}
                      >
                        <span>{t("btn_setting_agent")}</span>
                      </button>
                    )}
                    {(roleState == "AD") && (
                      <button
                        key="submit"
                        className="btn-green"
                        style={{ marginLeft: "6px", cursor: "pointer" }}
                        onClick={() => {
                          func_loadInsurerData();
                          setModalInsurer(true);
                        }}
                      >
                        <span>{t("btn_setting_insurer")}</span>
                      </button>
                    )}
                    {roleState == "AD" && (
                      <button
                        key="submit"
                        className="btn-green"
                        style={{ marginLeft: "6px", cursor: "pointer" }}
                        onClick={() => {
                          setExportState({
                            fromDate: null,
                            toDate: null
                          })
                          setModalExport(true);
                        }}
                      >
                        <span>{t("export_excel")}</span>
                      </button>
                    )}
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Table
              columns={columns}
              dataSource={datatable}
              style={{ width: "100%" }}
            />
          </Row>
        </Card>
      </div>

      <Modal
        title="ไฟล์ VDO"
        visible={modalvisiblevdo}
        onCancel={handleCancelvdo}
        width="80%"
        footer={[
          <Button key="back" onClick={handleCancelvdo}>
            {t("cancel")}
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <Space direction="vertical">
              <Table
                tableLayout={"fixed"}
                className="virtual-table"
                columns={columnsVdo}
                rowKey="id"
                scroll={true}
                dataSource={Vdorecord}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => { }, // click row
                  };
                }}
              />
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="ไฟล์รูป"
        visible={modalvisibleimage}
        onCancel={handleCancelimage}
        width="80%"
        footer={[
          <Button key="back" onClick={handleCancelimage}>
            {t("cancel")}
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <Space direction="vertical">
              <Row gutter={16}>
                <Col style={{ marginTop: "10px" }} span={24}>
                  <h2>{t("front_of_the_car")}</h2>
                </Col>

                {dataImage.insurance &&
                  dataImage.insurance.map((value, index) => (
                    <Col span={4}>
                      <a
                        href={
                          WEB_FILE + value.imagepath.replace("./public", "")
                        }
                        target="_blank"
                      >
                        <Card
                          style={{ marginTop: "10px" }}
                          hoverable
                          cover={
                            <img
                              alt="falcon"
                              src={
                                WEB_FILE +
                                value.imagepath.replace("./public", "")
                              }
                            />
                          }
                        >
                          <Meta
                            style={{ fontSize: 12 }}
                            title={t("front_of_the_car")}
                            description={
                              value.create_date ? (
                                <Moment format="DD-MM-YYYY HH:mm:ss">
                                  {value.create_date}
                                </Moment>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Card>
                      </a>
                    </Col>
                  ))}

                <Col style={{ marginTop: "10px" }} span={24}>
                  <h2>{t("back_of_the_car")}</h2>
                </Col>

                {dataImage.parties &&
                  dataImage.parties.map((value, index) => (
                    <Col span={4}>
                      <a
                        href={
                          WEB_FILE + value.imagepath.replace("./public", "")
                        }
                        target="_blank"
                      >
                        <Card
                          style={{ marginTop: "10px" }}
                          hoverable
                          cover={
                            <img
                              alt="falcon"
                              src={
                                WEB_FILE +
                                value.imagepath.replace("./public", "")
                              }
                            />
                          }
                        >
                          <Meta
                            style={{ fontSize: 12 }}
                            title={t("back_of_the_car")}
                            description={
                              value.create_date ? (
                                <Moment format="DD-MM-YYYY HH:mm:ss">
                                  {value.create_date}
                                </Moment>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Card>
                      </a>
                    </Col>
                  ))}

                <Col style={{ marginTop: "10px" }} span={24}>
                  <h2>ทรัพย์สิน</h2>
                </Col>

                {dataImage.asset &&
                  dataImage.asset.map((value, index) => (
                    <Col span={4}>
                      <a
                        href={
                          WEB_FILE + value.imagepath.replace("./public", "")
                        }
                        target="_blank"
                      >
                        <Card
                          style={{ marginTop: "10px" }}
                          hoverable
                          cover={
                            <img
                              alt="falcon"
                              src={
                                WEB_FILE +
                                value.imagepath.replace("./public", "")
                              }
                            />
                          }
                        >
                          <Meta
                            style={{ fontSize: 12 }}
                            title="ทรัพย์สิน"
                            description={
                              value.create_date ? (
                                <Moment format="DD-MM-YYYY HH:mm:ss">
                                  {value.create_date}
                                </Moment>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Card>
                      </a>
                    </Col>
                  ))}
              </Row>
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Gallery"
        visible={modalvisibleimageandvideo}
        onCancel={handleCancelimageandvdeo}
        width="80%"
        footer={(dataSelected.sys_status == "O" && (roleState == "AG" || roleState == "AD")) ? [
          <Button key="back" onClick={handleCancelimageandvdeo}>
            {t("cancel")}
          </Button>,
          <Button className="btn-green" key="complete" onClick={() => func_updateStatus(dataSelected, "C")}>
            {t("btn_complete")}
          </Button>,
          <Button className="btn-yellow" key="reopen" onClick={() => func_updateStatus(dataSelected, "R")}>
            {t("btn_reopen")}
          </Button>,
        ] : ((dataSelected.sys_status == "CN") && (roleState == "AD" || roleState == "AG")) ? [
          <Button key="back" onClick={handleCancelimageandvdeo}>
            {t("cancel")}
          </Button>,
          <Button className="btn-yellow" key="reopen" onClick={() => func_updateStatus(dataSelected, "R")}>
            {t("btn_reopen")}
          </Button>,
        ] : ((dataSelected.sys_status == "C" || dataSelected.sys_status == "RJ") && (roleState == "AD" || roleState == "AG")) ? [
          <Button key="back" onClick={handleCancelimageandvdeo}>
            {t("cancel")}
          </Button>,
          <Button className="btn-yellow" key="reopen" onClick={() => func_updateStatus(dataSelected, "SI")}>
            {t("btn_send_to_insurer")}
          </Button>,
        ]: ((dataSelected.sys_status == "SI") && (roleState == "IN")) ? [
          <Button key="back" onClick={handleCancelimageandvdeo}>
            {t("cancel")}
          </Button>,
          <Button className="btn-green" key="reopen" onClick={() => func_updateStatus(dataSelected, "AP")}>
            {t("btn_approve")}
          </Button>,
          <Button className="btn-yellow" key="reopen" onClick={() => func_updateStatus(dataSelected, "RJ")}>
            {t("btn_reject")}
          </Button>,
          <Button className="btn-red" key="reopen" onClick={() => func_updateStatus(dataSelected, "RC")}>
            {t("btn_reject_car")}
          </Button>,
        ] : [
          <Button key="back" onClick={handleCancelimageandvdeo}>
            {t("cancel")}
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <Space direction="vertical">
              <Row>
                <Col span={24}>
                  <Space direction="vertical">
                    <Table
                      tableLayout={"fixed"}
                      className="virtual-table"
                      columns={columnsVdo}
                      rowKey="id"
                      scroll={true}
                      dataSource={Vdorecord}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => { }, // click row
                        };
                      }}
                    />
                  </Space>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col style={{ marginTop: "10px" }} span={24}>
                  <h2>{t("front_of_the_car")}</h2>
                </Col>
                {dataImage.insurance &&
                  dataImage.insurance.map((value, index) => (
                    <Col span={4}>
                      <a
                        href={
                          WEB_FILE + value.imagepath.replace("./public", "")
                        }
                        target="_blank"
                      >
                        <Card
                          style={{ marginTop: "10px" }}
                          hoverable
                          cover={
                            <img
                              alt="falcon"
                              src={
                                WEB_FILE +
                                value.imagepath.replace("./public", "")
                              }
                            />
                          }
                        >
                          <Meta
                            style={{ fontSize: 12 }}
                            title={t("front_of_the_car")}
                            description={
                              value.create_date ? (
                                <Moment format="DD-MM-YYYY HH:mm:ss">
                                  {value.create_date}
                                </Moment>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Card>
                      </a>
                    </Col>
                  ))}
                <Col style={{ marginTop: "10px" }} span={24}>
                  <h2>{t("back_of_the_car")}</h2>
                </Col>
                {dataImage.parties &&
                  dataImage.parties.map((value, index) => (
                    <Col span={4}>
                      <a
                        href={
                          WEB_FILE + value.imagepath.replace("./public", "")
                        }
                        target="_blank"
                      >
                        <Card
                          style={{ marginTop: "10px" }}
                          hoverable
                          cover={
                            <img
                              alt="falcon"
                              src={
                                WEB_FILE +
                                value.imagepath.replace("./public", "")
                              }
                            />
                          }
                        >
                          <Meta
                            style={{ fontSize: 12 }}
                            title={t("back_of_the_car")}
                            description={
                              value.create_date ? (
                                <Moment format="DD-MM-YYYY HH:mm:ss">
                                  {value.create_date}
                                </Moment>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Card>
                      </a>
                    </Col>
                  ))}
                <Col style={{ marginTop: "10px" }} span={24}>
                  <h2>{t("side_of_the_car")}</h2>
                </Col>
                {dataImage.asset &&
                  dataImage.asset.map((value, index) => (
                    <Col span={4}>
                      <a
                        href={
                          WEB_FILE + value.imagepath.replace("./public", "")
                        }
                        target="_blank"
                      >
                        <Card
                          style={{ marginTop: "10px" }}
                          hoverable
                          cover={
                            <img
                              alt="falcon"
                              src={
                                WEB_FILE +
                                value.imagepath.replace("./public", "")
                              }
                            />
                          }
                        >
                          <Meta
                            style={{ fontSize: 12 }}
                            title={t("side_of_the_car")}
                            description={
                              value.create_date ? (
                                <Moment format="DD-MM-YYYY HH:mm:ss">
                                  {value.create_date}
                                </Moment>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Card>
                      </a>
                    </Col>
                  ))}
                <Col style={{ marginTop: "10px" }} span={24}>
                  <h2>{t("car_tank")}</h2>
                </Col>

                {dataImage.numberasset &&
                  dataImage.numberasset.map((value, index) => (
                    <Col span={4}>
                      <a
                        href={
                          WEB_FILE + value.imagepath.replace("./public", "")
                        }
                        target="_blank"
                      >
                        <Card
                          style={{ marginTop: "10px" }}
                          hoverable
                          cover={
                            <img
                              alt="falcon"
                              src={
                                WEB_FILE +
                                value.imagepath.replace("./public", "")
                              }
                            />
                          }
                        >
                          <Meta
                            style={{ fontSize: 12 }}
                            title={t("car_tank")}
                            description={
                              value.create_date ? (
                                <Moment format="DD-MM-YYYY HH:mm:ss">
                                  {value.create_date}
                                </Moment>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Card>
                      </a>
                    </Col>
                  ))}

                <Col style={{ marginTop: "10px" }} span={24}>
                  <h2>{t("other")}</h2>
                </Col>
                {dataImage.asset2 &&
                  dataImage.asset2.map((value, index) => (
                    <Col span={4}>
                      <a
                        href={
                          WEB_FILE + value.imagepath.replace("./public", "")
                        }
                        target="_blank"
                      >
                        <Card
                          style={{ marginTop: "10px" }}
                          hoverable
                          cover={
                            <img
                              alt="falcon"
                              src={
                                WEB_FILE +
                                value.imagepath.replace("./public", "")
                              }
                            />
                          }
                        >
                          <Meta
                            style={{ fontSize: 12 }}
                            title={t("other")}
                            description={
                              value.create_date ? (
                                <Moment format="DD-MM-YYYY HH:mm:ss">
                                  {value.create_date}
                                </Moment>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Card>
                      </a>
                    </Col>
                  ))}
              </Row>
              {(dataSelected.sys_status == "SI") && (roleState == "IN") && (
                <Row>
                  <Col span={24}>
                    <TextArea
                      rows={4}
                      value={comment}
                      onChange={(e) => {
                        set_comment(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={24}>
                  <Table
                    columns={columnsComment}
                    dataSource={commentdatatable}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
              {roleState != "IN" && (
                <Row>
                  <Col span={24}>
                    <div style={{ display: "flex", alignItems: 'flex-end', justifyContent: 'flex-end', gap: '1rem' }}>
                      <div >
                        <Button
                          onClick={() => {
                            func_createlink();
                          }}
                          type="primary"
                          htmlType="submit"
                          className="btn-primary"
                        >
                          Send SMS
                        </Button>
                      </div>
                      <div >
                        <Button
                          onClick={() => {
                            func_copy();
                          }}
                          type="primary"
                          htmlType="submit"
                          className="btn-primary"
                        >
                          Copy Link
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={t("create_a_new_appoinment")}
        visible={modalCreate}
        onCancel={handleCancelcreate}
        footer={[]}
        width="50%"
      >
        <Form name="trancall" form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("firstName")}</label>
                <Form.Item
                  //initialValue={callState.fristname}
                  name="fristname"
                  style={{ opacity: 0.8 }}
                  rules={[
                    {
                      required: true,
                      message: t("vfirstName"),
                    },
                  ]}
                >
                  <Input
                    readOnly={false}
                    value={callState.fristname}
                    onChange={(e) => {
                      setCallState({ ...callState, fristname: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("lastName")}</label>
                <Form.Item
                  //initialValue={callState.lastname}
                  name="lastname"
                  style={{ opacity: 0.8 }}
                  rules={[
                    {
                      required: true,
                      message: t("vlastName"),
                    },
                  ]}
                >
                  <Input
                    readOnly={false}
                    value={callState.lastname}
                    onChange={(e) => {
                      setCallState({ ...callState, lastname: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("phone_number")}</label>
                <Form.Item
                  //initialValue={callState.tel}
                  name="phone"
                  style={{ opacity: 0.8 }}
                  rules={[
                    {
                      required: true,
                      message: t("vphone_number"),
                    },
                    {
                      pattern: "^[0-9]{10}$",
                      message: t("Invalid_phone_number_format"),
                    },
                  ]}
                >
                  <Input
                    value={callState.tel}
                    onChange={(e) => {
                      setCallState({ ...callState, tel: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("insurer")}</label>
                <Form.Item
                  //initialValue={callState.tel}
                  name="insurer"
                  style={{ opacity: 0.8 }}
                  rules={[
                    {
                      required: true,
                      message: t("vinsurer"),
                    },
                  ]}
                >
                  <Select
                    value={callState.insurer}
                    onChange={(e) => {
                      setCallState({ ...callState, insurer: e });
                    }}
                  >
                    <Option value="">{t("please_select_insurer")}</Option>
                    {insurerdatatable.map((x) => {
                      return (
                        <Option value={x.insurer}>{x.insurer}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("brand")}</label>
                <Form.Item
                  //initialValue={callState.tel}
                  name="brand"
                  style={{ opacity: 0.8 }}
                  rules={[
                    // {
                    //   required: false,
                    //   message: t("vbrand"),
                    // },
                  ]}
                >
                  <Input
                    value={callState.brand}
                    onChange={(e) => {
                      setCallState({ ...callState, brand: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("model")}</label>
                <Form.Item
                  //initialValue={callState.tel}
                  name="model"
                  style={{ opacity: 0.8 }}
                  rules={[
                    // {
                    //   required: true,
                    //   message: t("vmodel"),
                    // },
                  ]}
                >
                  <Input
                    value={callState.model}
                    onChange={(e) => {
                      setCallState({ ...callState, model: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("plate_no")}</label>
                <Form.Item
                  //initialValue={callState.tel}
                  name="plate_no"
                  style={{ opacity: 0.8 }}
                  rules={[
                    // {
                    //   required: true,
                    //   message: t("vplate_no"),
                    // },
                  ]}
                >
                  <Input
                    value={callState.plate_no}
                    onChange={(e) => {
                      setCallState({ ...callState, plate_no: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("province")}</label>
                <Form.Item
                  //initialValue={callState.tel}
                  name="province"
                  style={{ opacity: 0.8 }}
                  rules={[
                    // {
                    //   required: true,
                    //   message: t("vprovince"),
                    // },
                  ]}
                >
                  <Input
                    value={callState.province}
                    onChange={(e) => {
                      setCallState({ ...callState, province: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("jlo_ref")}</label>
                <Form.Item
                  //initialValue={callState.jlo_ref}
                  name="jlo_ref"
                  style={{ opacity: 0.8 }}
                  rules={[
                    // {
                    //   required: true,
                    //   message: t("vjlo_ref"),
                    // },
                  ]}
                >
                  <Input
                    readOnly={false}
                    value={callState.jlo_ref}
                    onChange={(e) => {
                      setCallState({ ...callState, jlo_ref: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("crm_ref")}</label>
                <Form.Item
                  //initialValue={callState.crm_ref}
                  name="crm_ref"
                  style={{ opacity: 0.8 }}
                  rules={[
                    {
                      required: true,
                      message: t("vcrm_ref"),
                    },
                  ]}
                >
                  <Input
                    readOnly={false}
                    value={callState.crm_ref}
                    onChange={(e) => {
                      setCallState({ ...callState, crm_ref: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col span={24}>
              <div style={{ display: "flex" }}>
                <button
                  type="submit"
                  className="btn-green"
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                >
                  {t("create")}
                </button>

                <button
                  className="btn-red"
                  type="button"
                  onClick={handleCancelcreate}
                >
                  {t("cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={t("btn_setting_agent")}
        visible={modalagent}
        onCancel={() => setModalagent(false)}
        footer={[
          <Button key="back" onClick={() => setModalagent(false)}>
            {t("cancel")}
          </Button>]}
        width="50%"
      >
        <Row>
          <Table
            columns={columnsAgent}
            dataSource={agentdatatable}
            style={{ width: "100%" }}
          />
        </Row>
      </Modal>

      <Modal
        title={insurerState.id == 0 ? t("create_new_insurer") : t("edit_insurer")}
        visible={modalCreateInsurer}
        onCancel={handleCancelcreateInsurer}
        footer={[]}
        width="50%"
      >
        <Form name="trancall" form={form_insurer} layout="vertical" onFinish={onFinishInsurer}>
          <Row>
            <Col span={24}>
              <div style={{ padding: "4px" }}>
                <label>{t("insurer")}</label>
                <Form.Item
                  //initialValue={callState.fristname}
                  name="insurer"
                  style={{ opacity: 0.8 }}
                  rules={[
                    {
                      required: true,
                      message: t("vinsurer"),
                    },
                  ]}
                >
                  <Input
                    readOnly={false}
                    value={insurerState.insurer}
                    onChange={(e) => {
                      console.log("e.target.value::", e.target.value);
                      setInsurerState({ ...insurerState, insurer: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col span={24}>
              <div style={{ display: "flex" }}>
                <button
                  type="submit"
                  className="btn-green"
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                >
                  {t("create")}
                </button>

                <button
                  className="btn-red"
                  type="button"
                  onClick={handleCancelcreateInsurer}
                >
                  {t("cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={t("btn_setting_insurer")}
        visible={modalinsurer}
        onCancel={() => setModalInsurer(false)}
        footer={[
          <Button key="back" onClick={() => setModalInsurer(false)}>
            {t("cancel")}
          </Button>]}
        width="50%"
      >
        <Row>
          <Space direction="horizontal">
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setModalCreateInsurer(true);
                }}
            >
                {t("btn_add")}
            </Button>
          </Space>
          <Table
            columns={columnsInsurer}
            dataSource={insurerdatatable}
            style={{ width: "100%" }}
          />
        </Row>
      </Modal>

      <Modal
        title={t("confirm_reject")}
        visible={modalComment}
        onCancel={() => setModalComment(false)}
        footer={[]}
        width="50%"
      >
        <Form name="trancall" form={form_comment} layout="vertical" onFinish={onFinishComment}>
          <Row>
            <Col span={24}>
              <div style={{ padding: "4px" }}>
                <label>{t("comment")}</label>
                <Form.Item
                  //initialValue={callState.fristname}
                  name="comment"
                  style={{ opacity: 0.8 }}
                  rules={[]}
                >
                  <TextArea
                    rows={4}
                    readOnly={false}
                    value={comment}
                    onChange={(e) => {
                      set_comment(e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col span={24}>
              <div style={{ display: "flex" }}>
                <button
                  type="submit"
                  className="btn-green"
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                >
                  {t("confirm")}
                </button>

                <button
                  className="btn-red"
                  type="button"
                  onClick={() => setModalComment(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={t("btn_assign")}
        visible={modalReAssign}
        onCancel={() => setModalReAssign(false)}
        footer={[
          <Button className="btn-green" key="complete" onClick={(e) => func_submit_reassign(e)}>
            {t("btn_assign")}
          </Button>,
          <Button key="back" onClick={() => setModalReAssign(false)}>
            {t("cancel")}
          </Button>
        ]}
        width="50%"
      >
        <Row style={{ marginBottom: "10px" }}>
          <Col span={24}>
            <div className="flex">
              <div style={{ padding: "10px" }}>{t("label_search")}</div>
              <div style={{ padding: "10px" }}>
                <Input
                  value={searchAgent}
                  onChange={(e) => {
                    setSearchAgent(e.target.value);
                  }}
                />
              </div>
              <div style={{ padding: "10px" }}>
                <button
                  className="btn-blue"
                  onClick={() => {
                    func_loadAgentData();
                  }}
                >
                  {t("search")}
                </button>
              </div>
              <div style={{ padding: "10px" }}>
                <button
                  className="btn-blue"
                  type="button"
                  onClick={() => {
                    set_agent_selected(agentdatatable.filter((x) => x.is_shift == true))
                  }}
                >
                  {t("btn_select_all")}
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Table
            columns={columnsReAssign}
            dataSource={agentdatatable.filter((x) => x.is_shift == true)}
            style={{ width: "100%" }}
          />
        </Row>
      </Modal>

      <Modal
        title={t("export_excel")}
        visible={modalExport}
        onCancel={() => setModalExport(false)}
        footer={[]}
        width="50%"
      >
        <Form name="trancall" form={form} layout="vertical" onFinish={handleExport}>
          <Row>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("from_date")}</label>
                <Form.Item
                  initialValue={exportState.fromDate}
                  name="ตั้งแต่วันที่"
                  style={{ opacity: 0.8 }}
                  rules={[
                    {
                      required: true,
                      message: t("vfrom_date"),
                    },
                  ]}
                >
                  <DatePicker
                    value={exportState.fromDate}
                    defaultValue={exportState.fromDate}
                    style={{ width: "100%" }}
                    placeholder=""
                    // disabledDate={(d) => {
                    //   var date = new Date();
                    //   date.setDate(date.getDate() - 1);
                    //   return !d || d.isSameOrBefore(moment(date, "YYYY-MM-dd"));
                    // }}
                    onChange={(e) => {
                      setExportState({ ...exportState, fromDate: e });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ padding: "4px" }}>
                <label>{t("to_date")}</label>
                <Form.Item
                  initialValue={exportState.toDate}
                  name="ถึงวันที่"
                  style={{ opacity: 0.8 }}
                  rules={[
                    {
                      required: true,
                      message: t("vto_date"),
                    },
                  ]}
                >
                  <DatePicker
                    value={exportState.toDate}
                    defaultValue={exportState.toDate}
                    style={{ width: "100%" }}
                    placeholder=""
                    // disabledDate={(d) => {
                    //   var date = new Date();
                    //   date.setDate(date.getDate() - 1);
                    //   return !d || d.isSameOrBefore(moment(date, "YYYY-MM-dd"));
                    // }}
                    onChange={(e) => {
                      setExportState({ ...exportState, toDate: e });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col span={24}>
              <div style={{ display: "flex" }}>
                <button
                  type="submit"
                  className="btn-green"
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                >
                  {t("btn_export")}
                </button>

                <button
                  className="btn-red"
                  type="button"
                  onClick={() => setModalExport(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default VdoCallHistory;
