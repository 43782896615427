import React, { Component } from "react";
import {
  OTSession,
  OTStreams,
  preloadScript,
  createSession,
} from "opentok-react";
import ConnectionStatus from "./ConnectionStatus";
import Publisher from "./Publisher";
import Subscriber from "./Subscriber";
import queryString from "query-string";
import CM from "../../utils/CM";
import axios from "axios";
import { Modal, Button, Space, Card, Row, Col } from "antd";
import {
  PhoneOutlined,
  CameraOutlined,
  VideoCameraOutlined,
  CloseOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import "./index.scss";
import moment from "moment";
import { SUB_PATH, WEB_FILE } from "../../env";
import CSwl from "../../configs/customSweetAlert";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import i18n from '../../i18n';
const { Meta } = Card;
const CameraImage = require("../../assets/images/camera.png");
const ms = require("pretty-ms");

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      connected: false,
      params: {},
      counter: 0,
      token: "",
      session_id: "",
      custname: "",
      custphone: "",
      insurer: "",
      plate_no: "",
      province: "",
      brand: "",
      model: "",
      streams: [],
      session: "",
      video: false,
      video_id: "",
      video_loading: false,
      isSubscriberDisConnected: true,
      time: 0,
      isOn: false,
      start: 0,
      isToggleCamera: false,
      publisherAudio: true,
      camera_type: "insurance",
      images: [],
      userState: {},
      claimState: {},
      language: "",
    };

    this.session = React.createRef();
    this.publisher = React.createRef();
    this.subscriber = React.createRef();
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.setAudioPublisher = this.setAudioPublisher.bind(this);
    this.sessionEvents = {
      sessionConnected: () => {
        console.log("sessionConnected");
        this.setState({ connected: true });
      },
      sessionDisconnected: () => {
        // this.props.history.push("/home");
        // this.setState({ connected: false });
      },
      connectionDestroyed: () => {
        console.log("connectionDestroyed");
        // this.setState({ connected: false });
      },
    };
  }

  componentWillMount() {
    const { search } = this.props.location;
    let params = queryString.parse(search);
    this.setupBeforeUnloadListener();
    this.setState({ params: params });
  }

  getUser = async () => {
    let user = await localStorage.getItem("user");
    let claim = await localStorage.getItem("claim");
    if (user) {
      user = JSON.parse(user);
      claim = JSON.parse(claim);
      console.log(claim);
      this.setState({
        ...this.state,
        userState: user,
        claimState: claim
      })
    }
  };

  async componentDidMount() {
    const { room_id, type } = this.state.params;
    await this.getLanguage();
    try {
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/room/" + room_id,
        method: "get",
      });
      if (result.status === 200) {
        const { data } = result.data;
        const { token, session_id, custname, tel, insurer, plate_no, province, brand, model } = data;
        this.setState({
          token: token,
          session_id: session_id,
          custname: custname,
          custphone: tel,
          insurer: insurer,
          plate_no: plate_no,
          province: province,
          brand: brand,
          model: model,
        });
        this.getUser();
      }
    } catch (error) {
      console.log(error);
      const { data, status } = error.response;
      const { message } = data;
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => {
          window.location.href = SUB_PATH + "/home";
        },
      });
    }
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.doSomethingBeforeUnload();
    });
  };

  doSomethingBeforeUnload = () => {
    this.setState({ counter: 0 });
  };

  onError = (err) => {
    this.setState({ error: err });
  };

  subscriberConnected = async () => {
    this.startTimer();
    try {
      await CM.setHeaderAuth();
      await axios({
        url: "opentok/update",
        method: "post",
        data: { sysref: this.state.params.room_id, is_userconnect: true },
      });
    } catch (error) { }
    this.setState({ isSubscriberDisConnected: false });
  };

  getLanguage = async () => {
    const language = await CM.getStorage('language');
    if (language === null || language === "") {
      this.setState({ language: 'th' })
    } else {
      //i18n.changeLanguage(language);
      this.setState({ language: language })
    }
  }

  subscriberDisConnected = async () => {
    this.stopTimer();
    this.setState({ isSubscriberDisConnected: true });
    try {
      await CM.setHeaderAuth();
      await axios({
        url: "opentok/update",
        method: "post",
        data: { sysref: this.state.params.room_id, is_userconnect: false },
      });
    } catch (error) { }
  };

  subscriberDestroyed = async () => {
    this.stopTimer();
    this.setState({ isSubscriberDisConnected: true });
    try {
      await CM.setHeaderAuth();
      await axios({
        url: "opentok/update",
        method: "post",
        data: { sysref: this.state.params.room_id, is_userconnect: false },
      });
    } catch (error) { }
  };

  initPublisher = () => { };

  initSubscriber = () => { };

  componentWillUnmount() {
    if (this.sessionHelper) {
      this.sessionHelper.disconnect();
    }
    this.stopTimer();
    if (this.state.video) {
      this.videoEnd();
    }
  }

  endcallhandler = async () => {
    const language = await CM.getStorage('language');
    // Swal.showLoading();
    const result = await CSwl.SwalConfirm(language == "en" ? `Do you want to end this call?` : 'ต้องการสิ้นสุดการสนทนาหรือไม่?', language);
    if (result.isConfirmed) {
      const { room_id, type } = this.state.params;
      if (this.state.video) {
        await this.videoEnd();
      }
      try {
        await CM.setHeaderAuth();
        const result = await axios({
          url: "opentok/disconnect",
          method: "post",
          data: { room_id: room_id },
        });
        if (result.status === 200) {
          this.session.current.sessionHelper.session.disconnect();
          window.location.href = SUB_PATH + `/thankyouAdmin?room_id=${room_id}`;
        }
      } catch (error) {
        const { data, status } = error.response;
        const { message } = data;
        Modal.error({
          okText: "ปิด",
          content: message,
          onOk: async () => {
            window.location.href = SUB_PATH + "/home";
          },
        });
      }
    }

  };

  takePhoto = async (type) => {
    this.setState({ camera_type: type });
  };

  takePhotoIndex = async () => {
    const type = this.state.camera_type;
    if (this.subscriber.current.subscriber.current.getSubscriber()) {
      try {
        const { getImgData } =
          this.subscriber.current.subscriber.current.getSubscriber();
        const imageData = getImgData();

        // setTimeout(() => {
        //     this.setState({ image: '' });
        // }, 5000);

        fetch("data:image/png;base64," + imageData)
          .then((res) => res.blob())
          .then(async (blob) => {
            var bodyFormData = new FormData();
            const file = new File(
              [blob],
              //`${this.state.userState?.indivcompany}__${moment().format("YYYYMMDDHHMMSS")}_${type}.png`,
              `${this.state.model.replace(/\t/g, '').replace(/ /g, '')}_${this.state.plate_no.replace(/\t/g, '').replace(/ /g, '')}_${moment().format("DDMMYYYY")}_${type}.png`,
              { type: "image/png" }
            );
            bodyFormData.append("imagePicture", file);
            bodyFormData.append("type", type);
            await CM.setHeaderAuth();
            const result = await axios({
              url: "opentok/takephoto?room_id=" + this.state.params.room_id,
              method: "post",
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            this.setState({
              images: [
                ...this.state.images,
                { type: "camera", value: result.data.data },
              ],
            });
          });
      } catch (error) {
        const { data, status } = error.response;
        const { message } = data;
        Modal.error({
          okText: "ปิด",
          content: message,
          onOk: async () => {
            // window.location.href = '/home';
          },
        });
      }
    }
  };

  toggleCamera = () => {
    this.setState({ isToggleCamera: !this.state.isToggleCamera });
  };

  videoStart = async () => {
    try {
      this.setState({ video_loading: true });
      await CM.setHeaderAuth();
      const { room_id, type } = this.state.params;
      const result = await axios({
        url: "opentok/videoStart",
        method: "post",
        data: { session_id: this.state.session_id, room_id: room_id },
      });
      if (result.status === 200) {
        const { data } = result.data;
        if (data.id) {
          this.setState({ video_id: data.id });
        }
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
    this.setState({ video: true, video_loading: false });
  };

  videoEnd = async () => {
    if (this.subscriber.current.subscriber.current.getSubscriber()) {
      try {
        const { getImgData } =
          this.subscriber.current.subscriber.current.getSubscriber();
        const imageData = getImgData();
        this.setState({
          images: [
            ...this.state.images,
            { type: "video", value: "data:image/png;base64," + imageData },
          ],
        });
      } catch (error) { }
    }
    try {
      const { room_id, type } = this.state.params;
      this.setState({ video_loading: true });
      await CM.setHeaderAuth();
      const result = await axios({
        url: "opentok/videoEnd",
        method: "post",
        data: { archive_id: this.state.video_id, room_id: room_id },
      });
      if (result.status === 200) {
        const { data } = result.data;
        console.log("video:end", data);
      }
    } catch (error) {
      const { data, status } = error.response;
      const { message } = data;
      Modal.error({
        okText: "ปิด",
        content: message,
        onOk: async () => { },
      });
    }
    this.setState({ video: false, video_loading: false });
  };

  startTimer() {
    this.setState({
      isOn: true,
      time: this.state.time,
      start: Date.now() - this.state.time,
    });
    this.timer = setInterval(
      () =>
        this.setState({
          time: Date.now() - this.state.start,
        }),
      1000
    );
  }

  stopTimer() {
    this.setState({ isOn: false });
    clearInterval(this.timer);
  }
  resetTimer() {
    this.setState({ time: 0, isOn: false });
  }

  setAudioPublisher() {
    this.setState({ publisherAudio: !this.state.publisherAudio });
  }

  render() {
    return (
      <>
        <div className={"stream-container"}>
          {this.state.session_id === "" || this.state.token === "" ? null : (
            <OTSession
              onConnect={this.initSession}
              ref={this.session}
              apiKey={process.env.REACT_APP_API_KEY}
              sessionId={this.state.session_id}
              token={this.state.token}
              eventHandlers={this.sessionEvents}
              onError={this.onError}
            >
              {this.state.isSubscriberDisConnected === false && (
                <div
                  style={{
                    padding: 10,
                    borderRadius: 5,
                    fontWeight: "bold",
                    position: "absolute",
                    left: 30,
                  }}
                >
                  <div>
                    <label style={{ color: "#fff" }}>
                      {`${this.state.language == "th" ? "ชื่อลูกค้า" : "Customer"}`} : {this.state.custname}

                    </label>
                  </div>
                  <div>
                    <label style={{ color: "#fff" }}>
                      {`${this.state.language == "th" ? "เบอร์โทรลูกค้า" : "Phone"}`} : {this.state.custphone}

                    </label>
                  </div>
                  <div>
                    <label style={{ color: "#fff" }}>
                      {this.state.brand + " " + this.state.model}
                    </label>
                  </div>
                  <div>
                    <label style={{ color: "#fff" }}>
                      {this.state.plate_no + " " + this.state.province}
                    </label>
                  </div>
                  <div>
                    <label style={{ color: "#fff" }}>
                      {this.state.insurer}
                    </label>
                  </div>
                </div>
              )}

              {this.state.isSubscriberDisConnected === false &&
                this.state.images.length > 0 && (
                  <div
                    style={{
                      bottom: 20,
                      left: 30,
                      position: "absolute",
                      width: "30%",
                      overflow: "auto",
                      height: 200,
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {this.state.images.map((image, index) => {
                      return (
                        <div key={image.value}>
                          {image.type == "video" ? (
                            <Button
                              disabled={true}
                              type={"danger"}
                              shape="circle"
                              icon={<VideoCameraOutlined />}
                              className="image-icon-type"
                              size={"small"}
                            />
                          ) : (
                            <Button
                              disabled={true}
                              type={"default"}
                              shape="circle"
                              icon={<CameraOutlined />}
                              className="image-icon-type"
                              size={"small"}
                            />
                          )}

                          <img
                            onClick={() => {
                              if (image.type != "image") {
                                window.open(WEB_FILE + image.value.replace("./public", ""), "_blank")
                              }
                            }}
                            className={image.type != "video" ? "cursor-pointer" : ""}
                            width={120}
                            height={180}
                            title="image"
                            alt="title"
                            style={{
                              objectFit: "contain",
                              border: "1px solid #fff",
                            }}
                            src={image.type == "video" ?
                              image.value :
                              WEB_FILE + image.value.replace("./public", "")
                            }
                          />
                        </div>
                      );
                    })}
                    {/* <img width={150} height={200} alt="image" style={{ objectFit: 'contain', border: '1px solid #fff' }} src={this.state.image} /> */}
                  </div>
                )}

              {/* {
                                this.state.isSubscriberDisConnected === false
                                &&
                                <div className="text-center" style={{ padding: 10, borderRadius: 0, fontWeight: 'bold', position: 'absolute', zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.5)', marginTop: 22, width: 300 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <ClockCircleOutlined style={{ color: '#fff' }} />
                                        <div style={{ width: 10 }} />
                                        <label style={{ color: '#fff' }}>{ms(this.state.time + 1000.)}</label>
                                    </div>
                                </div>
                            } */}

              {this.state.error ? <div>{this.state.error}</div> : null}
              <ConnectionStatus connected={this.state.connected} />
              {
                this.state.userState?.id > 0 && <Publisher userState={this.state.userState} initPublisher={this.initPublisher} hide={false} />
              }


              <OTStreams>
                <div className="subscriber_host" style={{ height: 450 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <ClockCircleOutlined style={{ color: "#fff" }} />
                    <div style={{ width: 10 }} />
                    <label style={{ color: "#fff" }}>
                      {ms(this.state.time + 1000)}
                    </label>
                  </div>
                  <div style={{ display: "flex" }} className="text-center">
                    {
                      this.state.language && <Subscriber
                        takePhoto={this.takePhoto}
                        camera_type={this.state.camera_type}
                        publisherAudio={this.state.publisherAudio}
                        setAudioPublisher={this.setAudioPublisher}
                        isToggleCamera={this.state.isToggleCamera}
                        ref={this.subscriber}
                        params={this.state.params}
                        time={this.state.time}
                        language={this.state.language}
                        initSubscriber={this.initSubscriber}
                        subscriberDisConnected={this.subscriberDisConnected}
                        subscriberDestroyed={this.subscriberDestroyed}
                        subscriberConnected={this.subscriberConnected}
                      >
                        {!this.state.isSubscriberDisConnected == true && (
                          <div>
                            <div style={{ height: 20 }} />
                            <Space>
                              <div>
                                <Button
                                  onClick={this.endcallhandler}
                                  type="danger"
                                  shape="circle"
                                  icon={<PhoneOutlined />}
                                  size={"large"}
                                />
                              </div>
                              {/* <Button onClick={this.toggleCamera} type={"default"} shape="circle" icon={<CameraOutlined size={80} />} style={{ width: 50, height: 50 }} /> */}
                              <img
                                onClick={this.takePhotoIndex}
                                className="cursor-pointer"
                                width={60}
                                height={60}
                                style={{ objectFit: "cover" }}
                                src={CameraImage}
                                alt="microphone"
                              />
                              {this.state.video ? (
                                <Button
                                  style={{ backgroundColor: '#E3E3E3', borderColor: '#E3E3E3' }}
                                  loading={this.state.video_loading}
                                  onClick={this.videoEnd}
                                  type={"danger"}
                                  shape="circle"
                                  icon={<CloseOutlined style={{ color: '#000' }} />}
                                  size={"large"}
                                />
                              ) : (
                                <Button
                                  style={{ backgroundColor: '#E3E3E3', borderColor: '#E3E3E3' }}
                                  loading={this.state.video_loading}
                                  onClick={this.videoStart}
                                  type={"danger"}
                                  shape="circle"
                                  icon={<VideoCameraOutlined style={{ color: '#000' }} />}
                                  size={"large"}
                                />
                              )}
                            </Space>
                            {this.state.video && (
                              <div style={{ color: "#fff" }}>
                                กำลังบันทึกวิดีโอ
                              </div>
                            )}
                          </div>
                        )}
                      </Subscriber>
                    }

                  </div>
                </div>
              </OTStreams>
            </OTSession>
          )}
          {this.state.isSubscriberDisConnected === true && (
            <div
              style={{
                padding: 10,
                backgroundColor: "#FFFFFF",
                minWidth: 250,
                textAlign: "center",
                border: "red 2px solid",
                borderRadius: 5,
                fontWeight: "bold",
              }}
            >
              Waiting.... (K.{this.state.custname})
            </div>
          )}
          {this.state.isSubscriberDisConnected === true && (
            <div>
              <div style={{ height: 20 }} />
              <Space>
                <div>
                  <Button
                    onClick={this.endcallhandler}
                    type="danger"
                    shape="circle"
                    icon={<PhoneOutlined />}
                    size={"large"}
                  />
                </div>
              </Space>
            </div>
          )}
        </div>
      </>
    );
  }
}



export default preloadScript(App);
